import {
  APP_LOAD,
  AUTHENTICATE,
  LOGOUT,
  CAPTCHA_LOAD,
  REDIRECT,
  NAVIGATE_TO,
  ASYNC_START,
  ASYNC_END,
  TOKEN_LOAD,
  VIDEO_SETUP_COMPLETE,
  SIGNATURE_COLLECTED,
  INVITE_ACCEPTED,
  INVITE_CREATED,
  RESET_PASSWORD,
  REQUEST_PASSWORD_RESET,
  COMPONENT_LOAD,
  ENCOUNTER_ACCEPTED,
  ENCOUNTER_COMPLETE,
  REFRESH_USER,
  ADD_NURSING_HOME_FLAG,
  REFRESH_TOKEN, DISPLAY_RELOAD_BUTTON, PRACTICE_LIST,
} from "../constants/actionTypes";
import update from "immutability-helper";
import { Reducer } from "redux";
import { ICaptchaConfig, ICurrentUser, IlistPractice } from "../constants/Types";

const defaultState = {
  appName: "Reliant EMR",
  token: null,
  viewChangeCounter: 0,
  currentUser: null,
  permissions: [],
  errors: null,
  reloadButton: false,
  practiceList: null
};

type CommonState = {
  appName: string;
  token: null | string;
  viewChangeCounter: number;
  currentUser: null | ICurrentUser;
  permissions: string[];
  errors: null | any;
  appLoaded?: boolean;
  inProgress?: boolean;
  redirectTo?: null | string;
  targetPath?: null | string;
  idleWarning?: any;
  currentAPI_EMRHash?: string;
  reloadButton: boolean;
  captchaConfig?: ICaptchaConfig;
  practiceList: IlistPractice | null;
};

const commonReducer: Reducer<CommonState, any> = (state = defaultState, action): CommonState => {
  switch (action.type) {
    case APP_LOAD:
      return {
        ...state,
        token: action.token || null,
        appLoaded: true,
        // redirectTo: action.error || !action.token ? '/login' : null,
        // currentUser: action.payload ? action.payload.data : null,
        // targetPath: state.targetPath || action.targetPath
      };
    case ASYNC_START:
      return {
        ...state,
        inProgress: true,
      };
    case ASYNC_END:
      return {
        ...state,
        inProgress: false,
      };
    case REDIRECT:
      return {
        ...state,
        redirectTo: null,
      };
    case NAVIGATE_TO:
      return {
        ...state,
        redirectTo: action.targetPath,
        targetPath: null,
      };
    case AUTHENTICATE:
      return {
        ...state,
        redirectTo: action.error ? null : action.redirect || state.targetPath !== "/permissions-issue" && state.targetPath || "/",
        targetPath: null,
        idleWarning: null,
        errors: action.error ? (action.payload || {}).errors || [action.payload?.message || "internal error"] : null,
        currentUser: action.error ? null : action.payload,
        permissions: action.error || !action.payload ? [] : action.payload.permissions || [],
      };
      case PRACTICE_LIST:
      return {
        ...state,
        practiceList: action.payload,
      };
    // case SWITCH_PRACTICE:
    //   return update(state, {
    //     currentUser: {
    //       currentPracticeID: { $set: action.payload.currentPracticeID },
    //       isNursingHomeSite: { $set: action.isNursingHomeSite },
    //     },
    //   });
    case CAPTCHA_LOAD: {
      return {
        ...state,
        captchaConfig: action.payload
      }
    }
    case REFRESH_USER:
      return {
        ...state,
        currentUser: action.error ? null : action.payload,
        targetPath: action.targetPath,
      };
    case REFRESH_TOKEN:
      return {
        ...state,
        token: action.error ? null : action.payload,
        // create an item for the currentEHRHash
       currentAPI_EMRHash: action.error ? null : action.payload.currentEMRHash,
      };
    case ADD_NURSING_HOME_FLAG:
      return update(state, {
        currentUser: {
          isNursingHomeSite: { $set: action.isNursingHomeSite },
        },
      });
    case VIDEO_SETUP_COMPLETE:
      return update(state, { currentUser: { videoSetup: { $set: true } } });
    case SIGNATURE_COLLECTED:
      return update(state, {
        currentUser: { signatureUploaded: { $set: true } },
      });
    case TOKEN_LOAD:
      return {
        ...state,
        redirectTo: action.error || !action.payload ? action.redirectTo : action.targetPath || "/",
        targetPath: action.targetPath,
        currentUser: action.error ? null : action.payload,
        permissions: action.error || !action.payload ? [] : action.payload.permissions || [],
      };
    case LOGOUT:
      return {
        ...state,
        redirectTo: "/login",
        token: null,
        idleWarning: null,
        currentUser: null,
        targetPath: state.targetPath || action.targetPath,
        permissions: [],
      };
    case REQUEST_PASSWORD_RESET:
      return {
        ...state,
        errors: action.error ? (action.payload || {}).errors || [action.payload?.message || "internal error"] : null,
        redirectTo: action.error ? null : action.targetPath,
      };
    case INVITE_ACCEPTED:
    case RESET_PASSWORD:
    case COMPONENT_LOAD:
    case ENCOUNTER_ACCEPTED:
    case ENCOUNTER_COMPLETE:
    case INVITE_CREATED:
      // TODO: figure out a way to not have to add tons of actions in here to do this.
      return {
        ...state,
        redirectTo: action.error ? null : action.targetPath,
      };
    case DISPLAY_RELOAD_BUTTON:
      return {
        ...state,
        reloadButton: action.payload.reloadButton,
      };
    default:
      return state;
  }
};

export default commonReducer;
