import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Table, Button, Badge, Tooltip } from "reactstrap";

import { NOT_ENGAGED } from "../../constants/Encounter";
import ExtenderTag from "../../images/Extender.svg";
import ChatIcon from "../../images/ChatDots.svg";
import VideoCamera from "../../images/VideoCamera.svg";
import api from "../../api";
import {
  hasAnyPermission,
  hasPermission,
  PERMISSION_ADMIN,
  PERMISSION_MANAGE,
  PERMISSION_MEDICAL_DIRECTOR,
} from "../../constants/Permissions";
import PostponeModal from "./PostponeModal";
import { toast } from "react-toastify";
import { NAVIGATE_TO } from "../../constants/actionTypes";
import { errorLogger } from "../../utils";
import ShareIcon from "../../images/ShareButtonSmall.svg";

const mapStateToProps = (state, ownProps) => {
  return {
    permissions: state.common.permissions,
    currentUser: state.common.currentUser,
    engagementStatus: state.encounter.engagementStatus,
    currentTime: state.time.currentDate,
  };
};

const mapDispatchToProps = (dispatch) => ({
  viewEncounter: (id) =>
    dispatch({ type: NAVIGATE_TO, targetPath: `/encounter-monitor/${id}` }),
});

const TakingTest = ({ patient }) => {
  let timeRemaining = "Any second now";

  const inFuture = patient.completionTime && patient.completionTime > moment().format();

  if (patient.completionTime === "0001-01-01T00:00:00Z") {
    timeRemaining = "User is in intake";
  } else if (
    patient.completionTime &&
    patient.completionTime !== "0001-01-01T00:00:00Z" &&
    inFuture
  ) {
    timeRemaining = moment(patient.completionTime).fromNow(true);
  }
  return (
    <div>
      <div className="time-remaining">{timeRemaining}</div>
      {inFuture && <div className="test-status">until available</div>}
    </div>
  );
};

const TimeWaiting = ({ patient }) => {
  return (
    <div>
      <div className="waiting-status">Visit started</div>
      <div>
        <span className="time-waiting">{moment(patient.readyAt).fromNow(true)}</span>
        <span className="waiting-status"> ago</span>
      </div>
    </div>
  );
};

const Language = ({ languages }) => {
  let ls = languages || ["English"];
  if (ls.length > 1) {
    ls = languages.map((i, idx) => {
      if (idx == 0) {
        return `${i} preferred`;
      }
      return i;
    });
  }

  return <span>{ls.join(", ")}</span>;
};

const PatientItem = ({
  patient,
  canAccept,
  onAccept,
  onShowModal,
  isAdmin,
  isBetaTester,
  viewEncounter,
  index,
  currentUser,
  activityMonitor,
  showForwardModal
}) => {
  const available = patient.status == "available" || patient.status == "aborted";
  const scribeVisit = currentUser && currentUser.providerScribingStates && currentUser.providerScribingStates.includes(patient.location)
  // only allowed to accept top patient (patient who has waited longest), except in dev
  //  const active =
  //    available && (props.first || api.getEndpoint().includes("augustadev"));
  // This decision was changed, you can now accept any patient in the queue.
  const active = available;
  const onAcceptPatient = () => {
    onAccept(patient);
  };

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipAcceptBtn, setAcceptBtnOpen] = useState(false);

  const toggleAcceptBtn = () => setAcceptBtnOpen(!tooltipAcceptBtn);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const isMoreThanHour = () => {
    const minutesOut = patient.readyAt
      ? moment.duration(moment(Date.now()).diff(patient.readyAt)).asMinutes()
      : 0;
    return minutesOut >= 60;
  };
  const isOnline = moment.duration(moment().diff(moment(patient.patientLastSeen))).asSeconds() < 30;

  return (
    <tr className={active ? patient.status + " active" : patient.status || patient.encounterStatus}>
      <td>
        <div className="d-flex flex-column test-description">
          <div className="test-name">{patient.chiefComplaint ? patient.chiefComplaint : ""}</div>
          {patient.followUp && <div className="follow-up">Follow Up</div>}
        </div>
      </td>
      <td>
        <div className="practice-name">{patient.practiceName}</div>
      </td>
      <td>
        <div className="practice-name">{patient.patientName}</div>
      </td>
      <td>{patient.age || patient.patientAge} yo</td>
      <td>
        <Language languages={patient.languages} />
      </td>
      <td>
        <div className="text-center">{patient.location}</div>
      </td>

      <td>{patient.isClinicalServices && <ExtenderTag />}</td>
      {!!patient.communicationWithChat ? (
        <td>
          <ChatIcon /> Chat
        </td>
      ) : (
        <td>
          <VideoCamera /> Video
          {active && (
            <div role="button">
              <Badge
                id={"TooltipLastSeen-" + index}
                color={isOnline ? "success" : "secondary"}
                pill
              >
                {isOnline ? "Online" : "Offline"}
              </Badge>
              <Tooltip
                style={{ fontSize: 12, backgroundColor: "#4a8fe7" }}
                isOpen={tooltipOpen}
                target={"TooltipLastSeen-" + index}
                toggle={toggle}
              >
                Last seen:{" "}
                {patient?.patientLastSeen
                  ? moment(patient.patientLastSeen).format("MMMM Do YYYY, h:mm:ss a")
                  : "N/A"}
              </Tooltip>
            </div>
          )}
        </td>
      )}

      <td className="text-right">
        {!available && patient.encounterStatus !== "postponed" && <TakingTest patient={patient} />}
        {available && <TimeWaiting patient={patient} />}
        {patient.encounterStatus === "postponed" && (
          <div>
            <div className="waiting-status">Initialized at</div>
            <div>
              <span className="time-waiting">{moment(patient.doe).fromNow(true)}</span>
              <span className="waiting-status"> ago</span>
            </div>
          </div>)}
      </td>
      <td className="text-center">
        {active && !scribeVisit && !activityMonitor && (
          <>
            <Button
              id={"TooltipLastSeen-Accepted" + index}
              className="accept-button"
              disabled={!canAccept}
              onClick={onAcceptPatient}
              color="primary"
            >
              {canAccept ? "Accept" : "Already Accepted"}
            </Button>
          </>
        )}
        {active && scribeVisit && !activityMonitor && (
          <>
            <Button
              id={"TooltipLastSeen-Accepted" + index}
              className="accept-button"
              disabled={!canAccept}
              onClick={onAcceptPatient}
              color="primary"
            >
              {canAccept ? "Accept as Scribe" : "Already Accepted"}
            </Button>
          </>
        )}
        {activityMonitor && (
          <button onClick={showForwardModal} className="share-btn">
            <ShareIcon />
          </button>
        )}
        {/* {active && isMoreThanHour() && (
          <Button
            color="secondary"
            onClick={() => onShowModal(patient.referenceID)}
            className="postpone-button"
          >
            Postpone
          </Button>
        )} */}
        {isAdmin && isBetaTester && !activityMonitor && (
          <Button
            color="secondary"
            onClick={() => viewEncounter(patient.referenceID)}
            className="postpone-button"
          >
            Details
          </Button>
        )}
        {!available && patient.encounterStatus !== "postponed" && (
          <span className="ml-2">
            <i> Currently completing intake... </i>
          </span>
        )}
      </td>
    </tr>
  );
};

const SortPatients = (patients) => {
  patients.sort((a, b) => {
    let aTime, bTime;
    if (a.status !== b.status) {
      if (a.status === "active") {
        return -1;
      }
      if (b.status === "active") {
        return -1;
      }
    }

    if (
      a.completionTime !== "0001-01-01T00:00:00Z" &&
      b.completionTime !== "0001-01-01T00:00:00Z"
    ) {
      if (a.status === "pending" || a.status === "initialized") {
        aTime = moment(a.completionTime);
        bTime = moment(b.completionTime);
      }
      if (a.status === "available") {
        aTime = moment(a.readyAt);
        bTime = moment(b.readyAt);
      }
      if (aTime && aTime.isBefore(bTime)) {
        return -1;
      }
      if (bTime && bTime.isBefore(aTime)) {
        return 1;
      }
    }
    return 0;
  });
  return patients;
};

function PatientList({
  permissions,
  items,
  engagementStatus,
  onAcceptPatient,
  currentTime,
  currentUser,
  viewEncounter,
  title,
  activityMonitor = false,
  showForwardModal
}) {
  const [patients, setPatients] = useState([]);
  const [practices, setPractices] = useState({});
  const [modalShown, setPostponeModal] = useState(false);
  const [encounterKey, setEncounterKey] = useState({});

  const isAdmin = hasPermission(permissions, PERMISSION_ADMIN);
  const isBetaTester = !!currentUser?.isBetaTester;

  const onShowModal = (referenceID) => {
    const splitedReferenceID = referenceID.split("|");
    const EncounterKey = {
      ID: splitedReferenceID[0],
      Kind: splitedReferenceID[1],
    };
    setEncounterKey(EncounterKey);
    setPostponeModal(true);
  };

  const onCancelModal = () => {
    setEncounterKey({});
    setPostponeModal(false);
  };

  const onDeleteEncounter = async () => {
    try {
      await api.Encounters.deleteEncounter(encounterKey);
      onCancelModal();
    } catch (e: any) {
      setEncounterKey({});
      if (currentUser?.isBetaTester) {
        errorLogger(e)
      }
      onCancelModal();
    }
  };

  useEffect(() => {
    const pats = SortPatients(items);
    setPatients(pats);
  }, [items]);

  if (!items || items.length === 0) {
    return null;
  }

  return (
    <>
      <PostponeModal visible={modalShown} onCancel={onCancelModal} onDelete={onDeleteEncounter} />
      <h2>{title}</h2>
      <Table className={`patient-list ${activityMonitor ? "smallRow" : "" }`}>
        <thead>
          <tr>
            <td width="15%"></td>
            <td width="15%"></td>
            <td width="15%"></td>
            <td width="5%" className="align-text-center"></td>
            <td width="5%" className="align-text-center"></td>
            <td width="5%" className="align-text-center"></td>
            <td width="10%"></td>
            <td width="5%"></td>
            <td width="10%"></td>
            <td width="15%"></td>
          </tr>
        </thead>
        <tbody>
          {patients.map((patient, idx) => (
            <PatientItem
              showForwardModal={showForwardModal}
              activityMonitor={activityMonitor}
              viewEncounter={viewEncounter}
              isBetaTester={isBetaTester}
              isAdmin={isAdmin}
              onShowModal={onShowModal}
              canAccept={engagementStatus === NOT_ENGAGED && !patient.acceptedByDifferentProvider}
              onAccept={onAcceptPatient}
              index={idx}
              key={idx}
              patient={patient}
              currentUser={currentUser}
            />
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientList);
