import React, { FC, useEffect, useState } from "react";
import { Button, Input } from "reactstrap";
import { IChatTemplatesMessages, ITemplate, ITemplateBody } from "../../../constants/Types";
import style from "./index.scss";
import { toast } from "react-toastify";
import bugsnag from "../../../services/bugsnag";
import api from "../../../api";
import classNames from "classnames";
import { checkIsJSON, errorLogger } from "../../../utils";
import ToastSound from "../../ToastSound";
import ToastWithSound from "../../ToastSound";

type Props = {
  onClose: () => void;
  providerTemplates: ITemplateBody[];
  isShown: boolean;
  onUpdateTemplates: (templates: Promise<IChatTemplatesMessages>) => void;
};

const initialTemplate: ITemplateBody = {
  value: "New Template",
  label: "New Template",
};

const TemplateEditModal: FC<Props> = ({
  providerTemplates,
  isShown,
  onClose,
  onUpdateTemplates,
  encounterID,
}) => {
  const [editTemplateIndex, setEditTemplateIndex] = useState(0);
  const [editedTemplates, setEditedTemplates] = useState<ITemplateBody[]>([]);
  const [newTemplateCreated, setNewTemplateCreated] = useState(false);

  const updateTemplateFields = (event) => {
    const newTemplate = [...editedTemplates];
    newTemplate[editTemplateIndex][event.target.name] = event.target.value;
    setEditedTemplates(newTemplate);
  };

  const addTemplate = () => {
    setNewTemplateCreated(true);
    setEditedTemplates([...editedTemplates, initialTemplate]);
    setEditTemplateIndex(editedTemplates.length);
  };

  useEffect(() => {
    const deepCopy = providerTemplates.map((el) => ({ ...el }));
    setEditedTemplates(deepCopy);

    const defineIndex = deepCopy.findIndex(
      (element) => element.label === editedTemplates[editTemplateIndex]?.label,
    );
    if (defineIndex !== -1) setEditTemplateIndex(defineIndex);
  }, [providerTemplates.length]);

  const onSelectTemplate = (index) => {
    if (editTemplateIndex === index) {
      return;
    }
    const isChanged =
      editedTemplates[editTemplateIndex].label !== providerTemplates[editTemplateIndex]?.label ||
      editedTemplates[editTemplateIndex].value !== providerTemplates[editTemplateIndex]?.value;

    let isConfirmed = true;
    if (isChanged) {
      isConfirmed = confirm(
        `Changes is not saved for "${editedTemplates[editTemplateIndex].label}" template, do you want to continue?`,
      );
    }
    if (isConfirmed) { // confirm action
      setEditTemplateIndex(index);
      const deepCopy = providerTemplates.map((el) => ({ ...el }));
      setEditedTemplates(deepCopy);
      if (newTemplateCreated) {
        const slicedValue = [...editedTemplates].slice(0, -1); // remove new (not saved) template
        setEditedTemplates(slicedValue);
      }
      setNewTemplateCreated(false);
    }
  };

  const onDeleteTemplate = async () => {
    const isConfirmed = confirm(
      `Are you sure you want to delete "${editedTemplates[editTemplateIndex].label}" template`,
    );
    if (isConfirmed) {
      try {
        await api.Encounters.deleteChatTemplates(providerTemplates[editTemplateIndex].label);
        onUpdateTemplates(api.Encounters.getChatTemplates(encounterID!));
        setEditTemplateIndex(0);
        toast.success("Template deleted!");
      } catch (e) {
        errorLogger(e)
      }
    }
  };

  const onSaveTemplate = async () => {
    try {
      await api.Encounters.updateChatTemplates(
        editedTemplates[editTemplateIndex],
        providerTemplates[editTemplateIndex].label,
      );
      onUpdateTemplates(api.Encounters.getChatTemplates(encounterID!));
      toast.success(() => <ToastWithSound text={"Template Saved!"}/>);
    } catch (e) {
      errorLogger(e)

    }
  };

  const onCreateTemplate = async () => {
    try {
      await api.Encounters.addChatTemplates(editedTemplates[editTemplateIndex]);
      onUpdateTemplates(api.Encounters.getChatTemplates(encounterID!));
      setNewTemplateCreated(false);
      toast.success("Template Created!");
    } catch (e) {
      errorLogger(e)
    }
  };
  const onPasterShortCuts = (event) => {
    const newTemplate = [...editedTemplates];
    newTemplate[editTemplateIndex].value += ` {${event.target.name}}`;
    setEditedTemplates(newTemplate);
  };

  if (!isShown) {
    return;
  }
  return (
    <div className="modal-overlay">
      <div className={style.modalContainer}>
        <Button size="lg" className={style.closeModalBtn} onClick={onClose} close />
        <h4 className="mb-2">Edit your templates</h4>
        <div className="center d-flex h-100 w-100 mb-3">
          <div className={style.templatePreviewContainer}>
            <div className={style.templateLabelWrapper}>
              {editedTemplates &&
                editedTemplates.map((item, index) => {
                  return (
                    <div
                      key={`templatePreviewItem-${index}`}
                      className={classNames(
                        style.templatePreviewItem,
                        index === editTemplateIndex && style.selected,
                      )}
                      onClick={() => onSelectTemplate(index)}
                    >
                      {item.label}
                    </div>
                  );
                })}
            </div>
            <div className={style.addTemplateWrapper}>
              {providerTemplates[editTemplateIndex] && (
                <Button color="error" className="ml-auto" type="button" onClick={onDeleteTemplate}>
                  Delete
                </Button>
              )}
              {!newTemplateCreated && (
                <Button onClick={addTemplate} color="primary">
                  Add
                </Button>
              )}
            </div>
          </div>
          <div className={style.templateEditorContainer}>
            {editedTemplates.length > 0 && (
              <>
                <div className={style.templateEditorItem}>
                  <>
                    <Input
                      name="label"
                      onChange={updateTemplateFields}
                      className="mb-2"
                      value={editedTemplates[editTemplateIndex].label}
                      maxLength={29}
                    />
                    <Input
                      className={style.editTemplateTextarea}
                      name="value"
                      type="textarea"
                      value={editedTemplates[editTemplateIndex].value}
                      onChange={updateTemplateFields}
                    />
                  </>
                </div>
                <div className="mt-2 d-flex w-100 mt-2">
                  <Button
                    onClick={onPasterShortCuts}
                    name="ProviderName"
                    className={style.shortcutBtn}
                    size="sm"
                    color="primary"
                  >
                    Provider name
                  </Button>
                  <Button
                    onClick={onPasterShortCuts}
                    name="PatientName"
                    className={style.shortcutBtn}
                    size="sm"
                    color="primary"
                  >
                    Patient name
                  </Button>
                  <Button
                    onClick={onPasterShortCuts}
                    name="ProviderSuffix"
                    className={style.shortcutBtn}
                    size="sm"
                    color="primary"
                  >
                    Provider Suffix
                  </Button>
                </div>
                <div className="mt-2 w-100 d-flex justify-content-end">
                  <Button
                    color="primary"
                    onClick={newTemplateCreated ? onCreateTemplate : onSaveTemplate}
                    disabled={
                      editedTemplates[editTemplateIndex].label ===
                        providerTemplates[editTemplateIndex]?.label &&
                      editedTemplates[editTemplateIndex].value ===
                        providerTemplates[editTemplateIndex]?.value
                    }
                  >
                    Save
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateEditModal;
