import React, { Component, Dispatch } from "react";
import { connect } from "react-redux";
import Pusher from "pusher-js";

import api from "../api";
import {
  RTC_INFO,
  WAITING_ROOM_AVAILABLE,
  WAITING_ROOM_ASSIGNED,
  WAITING_ROOM_PENDING,
  WAITING_ROOM_LOAD_PENDING,
  WAITING_ROOM_LOAD_AVAILABLE,
  WAITING_ROOM_CANCEL,
  ENCOUNTER_USER_VIDEO_STATE,
  ENCOUNTER_ACCEPTED,
  ENCOUNTER_EJECTED,
  WAITING_ROOM_MESSAGE,
  ACTIVITY_SUMMARY,
  ENCOUNTER_LOAD_UNSIGNED,
  COMPONENT_LOAD,
  ENCOUNTER_CHAT_DATA,
  ENCOUNTER_IMAGES, ENCOUNTER_DETAILS,
} from "../constants/actionTypes";
import {
  hasAnyPermission,
  hasPermission,
  PERMISSION_SEE_PATIENTS,
  PERMISSION_THERAPIST,
} from "../constants/Permissions";

import { IChatHistoryItem, ICurrentUser, IPusherProviderInfo } from "../constants/Types";
import EMRLogo from "../images/emr-logo.png"
import { IAppState } from "../reducer";

import { toast } from "react-toastify";
import { checkIsJSON, errorLogger, showPushNotification } from "../utils";
import ToastWithSound from "./ToastSound";

const mapStateToProps = (state: IAppState) => {
  return {
    currentUser: state.common.currentUser,
    permissions: state.common.permissions,
    encounterRef: state.encounter.referenceID,
    info: state.rtc.info,
    encounterID: state.encounter.encounterID,
    asyncVisit: state.encounter.details?.asyncChat,
    chatHistory: state.componentData[ENCOUNTER_CHAT_DATA],
    encounterImages: state.componentData[ENCOUNTER_IMAGES],
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  onChatUpdate: (payload) =>
    dispatch({ type: COMPONENT_LOAD, subtype: ENCOUNTER_CHAT_DATA, payload }),
  loadInfo: (payload) => dispatch({ type: RTC_INFO, payload }),
  loadDetails: (payload) => dispatch({ type: ENCOUNTER_DETAILS, payload }),
  pendingEncounter: (encounter) => dispatch({ type: WAITING_ROOM_PENDING, encounter }),
  availableEncounter: (encounter) => dispatch({ type: WAITING_ROOM_AVAILABLE, encounter }),
  assignedEncounter: (encounter, toMe) =>
    dispatch({ type: WAITING_ROOM_ASSIGNED, encounter, toMe }),
  ejectedEncounter: (payload) =>
    dispatch({ type: ENCOUNTER_EJECTED, targetPath: "/waiting-room", payload }),
  cancelEncounter: (encounter) => dispatch({ type: WAITING_ROOM_CANCEL, encounter }),
  loadPending: (payload) => dispatch({ type: WAITING_ROOM_LOAD_PENDING, payload }),
  loadAvailable: (payload) => dispatch({ type: WAITING_ROOM_LOAD_AVAILABLE, payload }),
  encounterAccepted: (payload, resuming) =>
    dispatch({
      type: ENCOUNTER_ACCEPTED,
      targetPath: "/encounter",
      payload,
      resuming,
    }),
  userVideoState: (payload) => dispatch({ type: ENCOUNTER_USER_VIDEO_STATE, payload }),
  waitingRoomMessage: (message) => dispatch({ type: WAITING_ROOM_MESSAGE, message }),
  updateActivitySummary: (payload) => dispatch({ type: ACTIVITY_SUMMARY, payload }),
  loadUnsignedEncounters: () =>
    dispatch({ type: ENCOUNTER_LOAD_UNSIGNED, payload: api.Encounters.unsignedEncounters() }),
  onEncounterImagesUpdate: (payload) =>
    dispatch({ type: COMPONENT_LOAD, subtype: ENCOUNTER_IMAGES, payload }),
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type State = {
  currentUser: ICurrentUser | null;
  permissions: string[];
  encounterRef: string | null;
  info: any;
  connected?: boolean;
  pusher?: any;
  started?: boolean;
};

class RtcMonitor extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      currentUser: this.props.currentUser,
      permissions: this.props.permissions,
      encounterRef: this.props.encounterRef,
      info: this.props.info,
    };
  }

  addMessageToChat = (message: IChatHistoryItem) => {
    let oldHistory = this.props.chatHistory && this.props.chatHistory.data;
    if (!oldHistory) {
      return;
    }
    let newHistoryItems = oldHistory?.chatHistoryItems || [];
    newHistoryItems.push(message);
    const payload = {
      ...oldHistory,
      chatHistoryItems: newHistoryItems,
    };
    this.props.onChatUpdate(payload);
  };

  subscribeToWaitingRoom(pusher, channelName) {
    const channel = pusher.subscribe(channelName);
    channel.bind("encounter-available", (data) => {
      this.props.availableEncounter(data);
    });
    channel.bind("encounter-pending", (data) => {
      this.props.pendingEncounter(data);
    });
    channel.bind("encounter-initialized", (data) => {
      this.props.pendingEncounter(data);
    });
    channel.bind("user-online-again", (data) => {
      if (
        this.props.currentUser?.enableBrowserNotifications &&
        this.props.currentUser?.enableToastifyNotifications
      ) {
        showPushNotification({
          title: `${data.visitType}`,
          body: `Patient is back online`,
          icon: EMRLogo,
        });
        toast.info(() => <ToastWithSound text={`${data.visitType} Patient is back online`} />);
      }
      else if (this.props.currentUser?.enableBrowserNotifications) {
        showPushNotification({
          title: `${data.visitType}`,
          body: `Patient is back online`,
          icon: EMRLogo,
        });
      }
      else if (this.props.currentUser?.enableToastifyNotifications) {
        toast.info(() => <ToastWithSound text={`${data.visitType} Patient is back online`} />);
      }
    });
    channel.bind("encounter-assigned", (data) => {
      const toMe = data.providerID === this.props.currentUser?.providerID;
      if (!toMe && this.props.encounterRef && data.referenceID === this.props.encounterRef) {
        this.props.waitingRoomMessage(
          "The patient was assigned to another provider; you may accept another patient",
        );
      }
      this.props.assignedEncounter(data, toMe);
    });
    channel.bind("encounter-cancel", (data) => {
      if (this.props.encounterRef && data.referenceID === this.props.encounterRef) {
        this.props.waitingRoomMessage(
          "The patient postponed or canceled the encounter; you may accept another patient",
        );
      }
      this.props.cancelEncounter(data);
    });
  }

  subscribeToProvider(pusher, channelName) {
    if (!channelName) return;
    const channel = pusher.subscribe(channelName);
    channel.bind("provider-assignment", (data: { encounterID?: string; status: string }) => {
      if (data.status === "Reassignment") {
        this.props.loadUnsignedEncounters();
      } else {
        this.props.encounterAccepted(data, false);
      }
    });
    channel.bind("encounter-user-video-state", (data) => {
      this.props.userVideoState(data);
    });
    channel.bind("provider-ejected", (data) => {
      this.props.ejectedEncounter(data);
    });
    channel.bind(
      "encounter-image",
      (data: {
        imagePath: string;
        referenceID: string;
        rawImagePath: string;
        thumbnailPath: string;
        uploadedDate: string;
        addToChat: boolean;
        patientName: string;
        isImage: boolean;
      }) => {
        if (data.referenceID === this.props.encounterRef) {
          if (!data.isImage && this.props.encounterID) {
            try {
              this.props.loadDetails(api.Encounters.details(this.props.encounterID));
            } catch (e: any) {
              errorLogger(e)
            }
            return
          }
          if (this.props.encounterImages?.data) {
            let oldData = this.props.encounterImages.data;
            let oldImages = oldData.images || [];
            this.props.onEncounterImagesUpdate({
              ...oldData,
              images: [
                ...oldImages,
                {
                  rawImagePath: data.rawImagePath,
                  path: data.imagePath,
                  thumbnailPath: data.thumbnailPath,
                  uploadedDate: data.uploadedDate,
                },
              ],
            });
            this.props.onChatUpdate(
              api.Encounters.encounterHistoryChat(this.props.encounterRef!),
            );
          }
        }
      },
    );
    channel.bind(
      "async-message",
      (data: {
        patientName: string;
        writtenAt: string;
        messageText: string;
        encounterKey: {
          ID: string;
          Kind: string;
        };
      }) => {
        // there is new async message update unsigned encounters
        this.props.loadUnsignedEncounters();
        if (
          this.props.asyncVisit &&
          this.props.encounterRef === `${data.encounterKey.ID}|${data.encounterKey.Kind}`
        ) {
          const newMessage = {
            autoGenerated: false,
            patientMessage: true,
            writtenBy: data.patientName,
            createdAt: data.writtenAt,
            textContent: data.messageText,
            endOfPrevMessages: false,
            isImage: false,
          };
          this.addMessageToChat(newMessage);
        }
      },
    );
    channel.bind("chat-link-update", (data) => {
      this.props.onChatUpdate(
        api.Encounters.encounterHistoryChat(this.props.encounterRef!),
      );
    });

    channel.bind("chat-full-update", (data) => {
      this.props.onChatUpdate(
        api.Encounters.encounterHistoryChat(this.props.encounterRef!),
      );
    });

    channel.bind("scribe-encounter", (data) => {
      this.props.loadUnsignedEncounters();
    });
  }

  subscribeToMonitoring(pusher, channelName) {
    if (!channelName) return;

    const channel = pusher.subscribe(channelName);
    channel.bind("monitoring-summary", (data) => {
      this.props.updateActivitySummary(data);
    });
  }

  connectToPusher() {
    const info = this.props.info as IPusherProviderInfo;

    let pusher = new Pusher(info.pusherKey, {
      cluster: info.cluster,
      forceTLS: true,
      authEndpoint: `${api.getEndpoint()}/pusher/authenticate`,
      auth: {
        headers: {
          "x-api-key": api.getKey(),
          Authorization: `bearer ${api.getToken()}`,
        },
      },
    });

    pusher.connection.bind("error", (err) => console.log("Error", err));
    pusher.connection.bind("connected", () => {
      this.setState({ connected: true });
    });

    pusher.connection.bind("disconnected", () => {
      this.setState({ connected: false });
    });

    info.waitingRoomChannels.forEach((name) => this.subscribeToWaitingRoom(pusher, name));
    this.subscribeToProvider(pusher, info.providerChannel);
    this.subscribeToMonitoring(pusher, info.monitoringChannel);
    this.setState({ pusher, started: true });
  }

  disconnectFromPusher() {
    if (this.state.pusher) {
      this.state.pusher.disconnect();
      this.setState({ pusher: null, started: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.currentUser && this.state.pusher) {
      this.disconnectFromPusher();
      return;
    }

    if (this.props.currentUser && !prevProps.currentUser) {
      this.props.loadInfo(api.Pusher.info());
      const canSeePatients = hasAnyPermission(this.props.permissions, PERMISSION_SEE_PATIENTS, PERMISSION_THERAPIST);
      if (canSeePatients) {
        try {
          this.props.loadPending(api.Encounters.pending());
        }
        catch (e: any) {
          errorLogger(e)
        }
        try {
          this.props.loadAvailable(api.Encounters.available());
        }
        catch (e: any) {
          errorLogger(e)
        }
      }
    }

    if (this.props.currentUser && !this.props.info) {
      this.disconnectFromPusher();
      this.props.loadInfo(api.Pusher.info());
    }

    if (this.props.currentUser && !this.state.started && this.props.info) {
      this.connectToPusher();
    } else if (
      this.state.pusher &&
      this.state.pusher.connection.state === "disconnected" &&
      this.props.info
    ) {
      this.connectToPusher();
    }
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RtcMonitor);
