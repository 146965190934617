import React, { Component, Fragment, ReactText } from "react";
import { Input } from "reactstrap";
import pick from "lodash.pick";
import debounce from "lodash.debounce";
import isEqual from "react-fast-compare";
import moment from "moment";
import update from "immutability-helper";
import InfoEditWrapper from "./InfoEditWrapper";
import ChiefComplaint from "./ChiefComplaint";
import MedicalHistory from "./MedicalHistory";
import ReviewOfSystems from "./ReviewOfSystems";
import CurrentSymptoms from "./CurrentSymptoms";
import IllContacts from "./IllContacts";
import TravelHistory from "./TravelHistory";
import TestingReason from "./TestingReason";
import MedicationsTried from "./MedicationsTried";
import OtherIntake from "./OtherIntake";
import ConditionProgression from "./ConditionProgression";
import LungAllergyHistory from "./LungAllergyHistory";
import AllergyEnvironmentalEffect from "./AllergyEnvironmentalEffect";
import AllergySocialHistory from "./AllergySocialHistory";
import api from "../../api";
import bugsnagClient from "../../services/bugsnag";
import ContentBlock from "./ContentBlock";
import CentorScore from "./CentorScore";
import PertinentMedications from "./PertinentMedications";
import Vitals from "./Vitals";
import { isPCCUser } from "../../constants/Providers";
import Prescriptions from "./Prescriptions";
import HPI from "./HPI";
import PCCMedicalHistory from "./PCCMedicalHistory";
import Prescription from "./Prescription/Prescription";
import {
  allergyVisitType,
  sectionTitles,
  medicalHistoryFields,
  medicalIntakeFields,
  eyeAllergyIntakeFields,
  noseAllergyIntakeFields,
  skinAllergyIntakeFields,
  LungAllergyIntakeFields,
} from "../../constants/Encounter";

import OtherIntakeGeneral from "./OtherIntakeGeneral";
import {
  IAllergy,
  ICondition,
  IMedication,
  IPccPatientProfile,
  IObservation,
  IObservationList,
  IUserMedicalIntake,
  IMedicalProfile,
  IReviewOfSystems,
  IUserDetails,
  IProviderNote,
  IEncounterDetailsResponse,
  IEncounterImages,
  IPreviousPDFNotes,
} from "../../constants/Types";
import InHomeHealthVisit from "./InHomeHealthVisit";
import { getTitle } from "./Encounter";
import { CURRENT } from "./RxRequestVisit";
import PrevVisitNotes from "./PrevVisitNotes";

function isEmpty(obj: any) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

type Props = {
  setImageIndex: (idx: number) => void;
  previousPDFNotes?: IPreviousPDFNotes[];
  encounterImages?: IEncounterImages;
  imageModalOpenHandler: (title: string, imageBlock: ReactText) => void;
  HPIChecked?: boolean;
  checkForHPIUpdate?: Function;
  chiefComplaint: string;
  covid19Protocol?: boolean;
  covidNoteChange?: Function;
  covidProtocolChange?: Function;
  currentUser?: any;
  details?: any;
  encounterID: string;
  medicalIntake: IUserMedicalIntake;
  medicalProfile: Partial<IMedicalProfile>;
  note?: any;
  onIntakeChanged: Function;
  onIntakeSave: Function;
  onNoteUpdate: (field: string, value: any) => void;

  onProfileChanged: Function;
  onProfileSave: Function;
  onProviderNoteUpdate: (value: string) => void;
  onRemoveUnsavedSection: Function;
  patient: IUserDetails;
  pertinentMedicationsNotes?: string;
  providerNotes?: string;
  prevProviderNotes?: string;
  readOnly: boolean;
  testResult?: any;
  unsavedSections?: any;
  validateHPI?: boolean;
  visitType: string;
  vitals: string;
  isClinicalServices?: boolean;
  referenceID: string;
  missingInHomeHealthFields: {
    [key in keyof (IProviderNote & IEncounterDetailsResponse)]?: boolean;
  };
};

type State = {
  checked: boolean;
  observationsPCC: IObservation[];
  allergyIntolerance: IAllergy[];
  pccMedications: IMedication[];
  pccDiagnosis: ICondition[];
  pccPatient: IPccPatientProfile | {};
  initalHPIData: any;
  catalog: Partial<IReviewOfSystems>;
};

export default class PatientInfo extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      checked: false,
      observationsPCC: [],
      allergyIntolerance: [],
      pccMedications: [],
      pccDiagnosis: [],
      pccPatient: {},
      initalHPIData: {},
      catalog: {},
    };
  }

  componentDidMount() {
    this.loadCatalog();

    if (isPCCUser(this.props.currentUser)) {
      this.loadObservations();
      this.loadMedicalHistory();
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (!isEqual(prevProps.vitals, this.props.vitals)) {
      this.debounceVitalsUpdate();
    }
  }

  debounceVitalsUpdate = debounce(() => {
    api.Encounters.vitalsUpdate({ vitals: this.props.vitals }, this.props.encounterID || "");
  }, 500);

  loadCatalog = async () => {
    try {
      let result = await api.Catalog.reviewOfSystems();
      this.setState({ catalog: result });
    } catch (err: any) {
      bugsnagClient.notify(err);
    }
  };

  loadObservations = async () => {
    try {
      let result = await api.PCC.observations(this.props.patient.userID);
      const { observations } = this.latestObservations(result);
      this.setState({ observationsPCC: observations });
    } catch (err: any) {
      console.log("error", err, err?.details);
    }
  };

  latestObservations = (ob: IObservationList) => {
    const observations = (ob.observations || [])?.reduce((acc: IObservation[], entry) => {
      const index = acc.findIndex((item) => item.type === entry.type);
      if (index < 0) {
        acc.push(entry);
      } else {
        const currentObs = acc[index];
        if (
          entry.recordedDate &&
          currentObs.recordedDate &&
          moment(entry.recordedDate).isAfter(currentObs.recordedDate)
        ) {
          acc[index] = entry;
        }
      }
      return acc;
    }, []);
    return { observations };
  };

  loadMedicalHistory = async () => {
    try {
      let result = await api.PCC.allergies(this.props.patient.userID); // this request always returns an object
      this.setState({ allergyIntolerance: result.allergies || [] });
    } catch (err: any) {
      console.log("error", err, err.details);
    }
    try {
      let result = await api.PCC.medications(this.props.patient.userID, "active");
      this.setState({ pccMedications: result.medications || [] });
    } catch (err: any) {
      console.log("error", err, err.details);
    }
    try {
      let result = await api.PCC.conditions(this.props.patient.userID);
      this.setState({ pccDiagnosis: (result || {}).conditions || [] });
    } catch (err: any) {
      console.log("error", err, err.details);
    }
    try {
      let result = await api.PCC.patient(this.props.patient.userID);
      this.setState({ pccPatient: result || {} });
    } catch (err: any) {
      console.log("error", err, err.details);
    }
  };

  render() {
    let profile = this.props.medicalProfile;
    const hpi =
      this.state.initalHPIData && this.state.initalHPIData.medicalProfile
        ? this.state.initalHPIData.medicalProfile.providerNotes
        : "";

    const showFemaleSpecific =
      this.props.patient &&
      this.props.patient.sex == "F" &&
      this.props.visitType != "contraceptives";

    if (!profile) {
      return null;
    }
    const onMedicationsChange = (value: IMedication[]) => {
      this.props.onNoteUpdate("medications", value);
    };
    let intake = this.props.medicalIntake;
    if (!intake) {
      return null;
    }
    let visitType = this.props.visitType;

    const onCancelIntake = (destination: string) => (v) => {
      if (this.props.onIntakeChanged) {
        this.props.onIntakeChanged({ [destination]: v });
      }
    };
    const onSaveIntake =
      (...fields) =>
        () => {
          if (this.props.onIntakeSave) {
            this.props.onIntakeSave(fields);
          }
        };
    const onNoteChanged = (event) => {
      this.props.onNoteUpdate(event.target.name, event.target.value);
    };
    const onChiefComplaintChanged = (event) => {
      this.props.medicalIntake.chiefComplaint = {
        complaint: "" + event.target.value,
      };
      onSaveIntake("chiefComplaint")();
      onNoteChanged(event);
    };
    const onVitalsChanged = (event) => {
      this.props.medicalIntake.freeformVitals = event.target.value;
      onSaveIntake("freeformVitals")();
      onNoteChanged(event);
    };

    /* const onCancelMedicalHistory = (section) => (oldData) => {
      this.props.onRemoveUnsavedSection(section);
      this.props.onIntakeChanged()({
        womensHealth: oldData.medicalIntake.womensHealth,
      });
      if (oldData.medicalHistory.allergies === undefined) {
        oldData.medicalHistory.allergies = [];
      }
      if (oldData.medicalHistory.medications === undefined) {
        oldData.medicalHistory.medications = [];
      }
      this.props.onProfileChanged()(oldData.medicalHistory);
    }; */

    const onCancelIntakeHistory = (section) => (oldData) => {
      this.props.onRemoveUnsavedSection(section);
      this.props.onIntakeChanged()(oldData.intakeHistory);
    };

    const {
      currentSymptomsSection,
      eyeAllergySymptomsSection,
      noseAllergySymptomsSection,
      skinAllergySymptomsSection,
      LungAllergySymptomsSection,
      illContactsSection,
      travelHistorySection,
      testingReasonSection,
      medicationsTriedSection,
      eyeAllergyMedicationsTriedSection,
      noseAllergyMedicationsTriedSection,
      skinAllergyMedicationsTriedSection,
      lungAllergyMedicationsTriedSection,
      reviewOfSystemsSection,
      allergyEnvironmentalEffectsSection,
      allergySocialHistorySection,
      medicalHistorySection,
      conditionSpecificIntakeSection,
    } = sectionTitles;

    const onSaveMedicalHistory = (section: string, details: any) => {
      this.props.onRemoveUnsavedSection(section);
      this.props.onProfileSave(medicalHistoryFields, details);
    };

    const onSaveIntakeHistory = (fields, section: string) => {
      this.props.onRemoveUnsavedSection(section);
      this.props.onIntakeSave(fields);
    };

    const onIntakeChanged = (fields, section: string) => (newValues: any[]) => {
      this.props.onRemoveUnsavedSection(section);
      this.props.onIntakeChanged(section)(newValues);
      this.props.onIntakeSave(
        fields,
        update(this.props.details, {
          medicalIntake: { $merge: newValues },
        }),
      );
    };

    const onProfileChanged = (section: string) => (newValues: any) => {
      this.props.onProfileChanged(section)(newValues);
      onSaveMedicalHistory(
        section,
        update(this.props.details, {
          medicalProfile: { $merge: newValues },
        }),
      );
    };

    let patientIntakeNotes = (this.props.note && this.props.note.patientIntakeNotes) || "";
    const medicalHistory = pick(profile, medicalHistoryFields);

    const getIntakeHistory = (fields) => {
      let intakeHistory = pick(intake, fields);
      return { intakeHistory };
    };

    const intakeHistory = pick(intake, medicalIntakeFields);

    const prescriptionDetails = this.props.medicalIntake.rxRenewal;
    const isPCCPatient = isPCCUser(this.props.currentUser);

    if (visitType === "in_home_health") {
      return (
        <InHomeHealthVisit
          onChiefComplaintChanged={onChiefComplaintChanged}
          chiefComplaint={this.props.chiefComplaint}
          intake={intake}
        >
          <>
            <div className="block-section content-block">
              <MedicalHistory
                data={{ medicalIntake: intake, medicalHistory }}
                restoreData={{ medicalIntake: intake, medicalHistory }}
                title={medicalHistorySection}
                unsavedSections={this.props.unsavedSections}
                profile={profile}
                intake={intake}
                showFemaleSpecific={showFemaleSpecific}
                visitType={visitType}
                onProfileChanged={onProfileChanged(medicalHistorySection)}
                onIntakeChanged={onIntakeChanged(
                  ["womensHealth", "fluImmunization", "pertinentMedications"],
                  medicalHistorySection,
                )}
                patient={this.props.patient}
                isPCCPatient={isPCCPatient}
                onProviderNoteUpdate={this.props.onProviderNoteUpdate}
                providerNotes={this.props.providerNotes}
              />
            </div>
            <div className="block-section content-block">
              <ReviewOfSystems
                reviewOfSystems={intake.reviewOfSystemsKeys}
                pertinentNegatives={intake.rosNegatives}
                onChanged={onIntakeChanged(medicalIntakeFields, reviewOfSystemsSection)}
                symptomDetails={intake.symptomDetailsKeys}
                visitType={visitType}
                perSystemTitles={true}
                catalog={this.state.catalog}
                additionalRos={intake.reviewOfSystemsWritten}
                ROSGotSSC={intake.reviewOfSystemsGotSSC}
                symptomsDetailField={"symptomDetailsKeys"}
                symptomByStystemField={"reviewOfSystemsKeys"}
                readOnly={this.props.readOnly}
              />
            </div>
          </>
        </InHomeHealthVisit>
      );
    }
    return (

      <div className="patient-info">
        <InfoEditWrapper
          data={intake.chiefComplaint}
          readOnly={this.props.readOnly}
          onCancel={onCancelIntake("chiefComplaint")}
          onSave={onSaveIntake("chiefComplaint")}
        >
          <ChiefComplaint
            encounterID={this.props.referenceID}
            intake={intake}
            visitType={this.props.visitType}
            catalog={this.state.catalog}
            patient={this.props.patient || null}
            onChange={onChiefComplaintChanged}
            value={this.props.chiefComplaint}
          />
        </InfoEditWrapper>
        {/* When a provider account or a partner account submits “otherIntake” info, this will be displayed here. */}
        {intake.otherIntake && <OtherIntakeGeneral otherIntake={intake.otherIntake?.otherIntake} />}
        {isPCCPatient && (
          <div className="block-section content-block">
            <HPI
              onSave={() => this.props.onProfileSave(["providerNotes"])}
              onProviderNoteUpdate={this.props.onProviderNoteUpdate}
              providerNotes={this.props.providerNotes}
              prevProfile={this.props.prevProviderNotes}
              validateHPI={this.props.validateHPI}
              readOnly={this.props.readOnly}
            />
          </div>
        )}

        {this.props.visitType === "rx_request" && this.props.details?.followUp && (
          <ContentBlock title="Previous Visit Notes">
            <PrevVisitNotes
              previousPDFNotes={this.props.previousPDFNotes}
              chiefComplaint={this.props.chiefComplaint}
              userID={this.props.patient.userID}
            />
          </ContentBlock>
        )}

        <ContentBlock title="Patient Images">
          {this.props.encounterImages &&
            (this.props.encounterImages.images?.length ||
              this.props?.encounterImages?.previousEncountersImages?.reduce(
                (ac, a) => ac.concat(a),
                [],
              )?.length) ? (
            <>
              {this.props.encounterImages.previousEncountersImages?.map((previousImages, index) => {
                const title = getTitle(
                  index === 0,
                  this.props.chiefComplaint,
                  this.props?.previousPDFNotes && this.props?.previousPDFNotes[index]?.finishDate,
                );
                return (
                  <Fragment key={index}>
                    <div className="rxEncounterImagesTitle">{title}</div>
                    <div
                      className="rxEncounterImages"
                      onClick={() => this.props.imageModalOpenHandler(title, index)}
                    >
                      {previousImages?.length > 0 ? (previousImages?.map((item, idx) => (
                        <img
                          onClick={() => this.props.setImageIndex(idx)}
                          key={item.thumbnailPath}
                          src={item.thumbnailPath}
                          alt={`${this.props.chiefComplaint} image`}
                          height="80px"
                          width="80px"
                        />
                      ))) : (
                        <div>The patient did not upload images for this visit</div>
                      )}
                    </div>
                  </Fragment>
                );
              })}
              <div className="rxEncounterImagesTitle">Current Visit </div>
              {this.props.encounterImages && this.props.encounterImages.images?.length ? (
                <>
                  <div
                    className="rxEncounterImages"
                    onClick={() =>
                      this.props.imageModalOpenHandler("Current Visit Uploads", CURRENT)
                    }
                  >
                    {this.props.encounterImages?.images?.map((item, idx) => (
                      <img
                        onClick={() => this.props.setImageIndex(idx)}
                        key={item.thumbnailPath}
                        src={item.thumbnailPath}
                        alt={`${this.props.chiefComplaint} image`}
                        height="80px"
                        width="80px"
                      />
                    ))}
                  </div>
                </>
              ) : (
                <div>Patient has not yet uploaded images</div>
              )}
            </>
          ) : (
            <div>Patient has not yet uploaded images</div>
          )}
        </ContentBlock>
        <ContentBlock title="Patient Attachments">
          <ol>
            {this.props.details?.filesAttachments?.length ? (
              this.props.details.filesAttachments?.map((item, idx) => (
                <Fragment>
                  <li>
                    <a target="_blank" key={`filesAttachments-${idx}`} href={item.fileURL}>
                      Click to open
                    </a>
                  </li>
                </Fragment>
              )
              )) : (
              <div>Patient has not yet uploaded files</div>
            )}
          </ol>
        </ContentBlock>

        {!isPCCPatient ? (
          <ContentBlock className="block-section" title="Medical History">
            <MedicalHistory
              data={{ medicalIntake: intake, medicalHistory }}
              restoreData={{ medicalIntake: intake, medicalHistory }}
              title={medicalHistorySection}
              unsavedSections={this.props.unsavedSections}
              profile={profile}
              intake={intake}
              showFemaleSpecific={showFemaleSpecific}
              visitType={visitType}
              onProfileChanged={onProfileChanged(medicalHistorySection)}
              onIntakeChanged={onIntakeChanged(
                ["womensHealth", "fluImmunization", "pertinentMedications"],
                medicalHistorySection,
              )}
              patient={this.props.patient}
              isPCCPatient={isPCCPatient}
              onProviderNoteUpdate={this.props.onProviderNoteUpdate}
              providerNotes={this.props.providerNotes}
              readOnly={this.props.readOnly}
            />
          </ContentBlock>
        ) : (
          <ContentBlock className="block-section" title="Medical History">
            <PCCMedicalHistory
              allergies={this.state.allergyIntolerance}
              diagnosis={this.state.pccDiagnosis}
              patient={this.state.pccPatient}
            />
          </ContentBlock>
        )}

        {this.props.visitType === "nursing_home" && (
          <InfoEditWrapper
            data={intake.vitals}
            onCancel={onCancelIntake("vitals")}
            onSave={onSaveIntake("vitals")}
            readOnly={this.props.visitType === "nursing_home" ? true : this.props.readOnly}
            title={"vitals"}
            unsavedSections={this.props.unsavedSections}
          >
            <Vitals
              vitals={intake.vitals}
              onChanged={!this.props.readOnly ? this.props.onIntakeChanged("vitals") : null}
              isClinicalServices={this.props.isClinicalServices}
              visitType={this.props.visitType}
              onChange={onVitalsChanged}
              value={this.props.vitals}
              isPCCPatient={isPCCPatient}
              PCCObservations={this.state.observationsPCC}
            />
          </InfoEditWrapper>
        )}

        {visitType != allergyVisitType &&
          visitType != "contraceptives" &&
          visitType !== "nursing_home" &&
          visitType !== "rxrenewal" &&
          visitType !== "rx_request" &&
          visitType !== "video_poc" &&
          (
            <InfoEditWrapper
              data={{ intakeHistory }}
              readOnly={this.props.readOnly}
              onCancel={onCancelIntakeHistory(currentSymptomsSection)}
              onSave={() => onSaveIntakeHistory(medicalIntakeFields, currentSymptomsSection)}
              title={currentSymptomsSection}
              unsavedSections={this.props.unsavedSections}
            >
              <CurrentSymptoms
                reviewOfSystems={intake.currentSymptomsBySystem}
                pertinentNegatives={intake.currentSymptomNegatives}
                onChanged={
                  !this.props.readOnly ? this.props.onIntakeChanged(currentSymptomsSection) : null
                }
                symptomDetails={intake.currentSymptoms}
                visitType={visitType}
                perSystemTitles={false}
                catalog={this.state.catalog}
                symptomsDetailField={"currentSymptoms"}
                symptomByStystemField={"currentSymptomsBySystem"}
              />
            </InfoEditWrapper>
          )}

        {visitType === allergyVisitType && (
          <InfoEditWrapper
            readOnly={this.props.readOnly}
            data={getIntakeHistory(eyeAllergyIntakeFields)}
            onCancel={onCancelIntakeHistory(eyeAllergySymptomsSection)}
            onSave={() => onSaveIntakeHistory(eyeAllergyIntakeFields, eyeAllergySymptomsSection)}
            title={eyeAllergySymptomsSection}
            unsavedSections={this.props.unsavedSections}
          >
            <CurrentSymptoms
              reviewOfSystems={intake.allergyEyesSymptomsBySystem}
              pertinentNegatives={intake.eyeAllergySymptomNegatives}
              onChanged={
                !this.props.readOnly ? this.props.onIntakeChanged(eyeAllergySymptomsSection) : null
              }
              symptomDetails={intake.allergyEyesSymptoms}
              visitType={visitType}
              perSystemTitles={false}
              catalog={this.state.catalog}
              allergyType={"Allergy-eyes"}
              symptomsDetailField={"allergyEyesSymptoms"}
              symptomByStystemField={"allergyEyesSymptomsBySystem"}
            />
          </InfoEditWrapper>
        )}

        {visitType === allergyVisitType && (
          <InfoEditWrapper
            data={getIntakeHistory(noseAllergyIntakeFields)}
            readOnly={this.props.readOnly}
            onCancel={onCancelIntakeHistory(noseAllergySymptomsSection)}
            onSave={() => onSaveIntakeHistory(noseAllergyIntakeFields, noseAllergySymptomsSection)}
            title={noseAllergySymptomsSection}
            unsavedSections={this.props.unsavedSections}
          >
            <CurrentSymptoms
              reviewOfSystems={intake.allergyNoseSymptomsBySystem}
              pertinentNegatives={intake.noseAllergySymptomNegatives}
              onChanged={
                !this.props.readOnly ? this.props.onIntakeChanged(noseAllergySymptomsSection) : null
              }
              symptomDetails={intake.allergyNoseSymptoms}
              visitType={visitType}
              perSystemTitles={false}
              catalog={this.state.catalog}
              allergyType={"Allergy-nose"}
              symptomsDetailField={"allergyNoseSymptoms"}
              symptomByStystemField={"allergyNoseSymptomsBySystem"}
            />
          </InfoEditWrapper>
        )}

        {visitType === allergyVisitType && (
          <InfoEditWrapper
            data={getIntakeHistory(skinAllergyIntakeFields)}
            readOnly={this.props.readOnly}
            onCancel={onCancelIntakeHistory(skinAllergySymptomsSection)}
            onSave={() => onSaveIntakeHistory(skinAllergyIntakeFields, skinAllergySymptomsSection)}
            title={skinAllergySymptomsSection}
            unsavedSections={this.props.unsavedSections}
          >
            <CurrentSymptoms
              reviewOfSystems={intake.allergySkinSymptomsBySystem}
              pertinentNegatives={intake.skinAllergySymptomNegatives}
              onChanged={
                !this.props.readOnly ? this.props.onIntakeChanged(skinAllergySymptomsSection) : null
              }
              symptomDetails={intake.allergySkinSymptoms}
              visitType={visitType}
              perSystemTitles={false}
              catalog={this.state.catalog}
              allergyType={"Allergy-skin"}
              symptomsDetailField={"allergySkinSymptoms"}
              symptomByStystemField={"allergySkinSymptomsBySystem"}
            />
          </InfoEditWrapper>
        )}

        {visitType === allergyVisitType && (
          <InfoEditWrapper
            data={getIntakeHistory(LungAllergyIntakeFields)}
            onCancel={onCancelIntakeHistory(LungAllergySymptomsSection)}
            readOnly={this.props.readOnly}
            onSave={() => onSaveIntakeHistory(LungAllergyIntakeFields, LungAllergySymptomsSection)}
            title={LungAllergySymptomsSection}
            unsavedSections={this.props.unsavedSections}
          >
            <CurrentSymptoms
              reviewOfSystems={intake.allergyLungsSymptomsBySystem}
              pertinentNegatives={intake.lungAllergySymptomNegatives}
              onChanged={
                !this.props.readOnly ? this.props.onIntakeChanged(LungAllergySymptomsSection) : null
              }
              symptomDetails={intake.allergyLungsSymptoms}
              visitType={visitType}
              perSystemTitles={false}
              catalog={this.state.catalog}
              allergyType={"Allergy-lungs"}
              symptomsDetailField={"allergyLungsSymptoms"}
              symptomByStystemField={"allergyLungsSymptomsBySystem"}
            />
          </InfoEditWrapper>
        )}

        {intake.centorScore !== undefined && <CentorScore score={intake.centorScore} />}

        {intake.conditionProgression && intake.conditionProgression.progression !== "" && (
          // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'fields' implicitly has an 'any' type.
          <ConditionProgression progression={intake.conditionProgression} visitType={visitType} />
        )}

        {visitType !== "nursing_home" && visitType !== "rxrenewal" && visitType !== "rx_request" && (
          <InfoEditWrapper
            data={getIntakeHistory(["travelHistory"])}
            onCancel={onCancelIntakeHistory(travelHistorySection)}
            readOnly={this.props.readOnly}
            onSave={() => onSaveIntakeHistory(["travelHistory"], travelHistorySection)}
            title={travelHistorySection}
            unsavedSections={this.props.unsavedSections}
          >
            <TravelHistory
              travelHistory={intake.travelHistory}
              onChanged={
                !this.props.readOnly ? this.props.onIntakeChanged(travelHistorySection) : null
              }
              // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'fields' implicitly has an 'any' type.
              visitType={visitType}
            />
          </InfoEditWrapper>
        )}
        {visitType !== allergyVisitType &&
          visitType !== "contraceptives" &&
          visitType !== "nursing_home" &&
          visitType !== "rxrenewal" &&
          visitType !== "rx_request" &&
          visitType !== "video_poc" &&
          (

            <InfoEditWrapper
              data={getIntakeHistory(["illContacts"])}
              onCancel={onCancelIntakeHistory(illContactsSection)}
              readOnly={this.props.readOnly}
              onSave={() => onSaveIntakeHistory(["illContacts"], illContactsSection)}
              title={illContactsSection}
              unsavedSections={this.props.unsavedSections}
            >
              <IllContacts
                illContacts={intake.illContacts}
                recentExposure={intake.recentExposure}
                onChanged={
                  !this.props.readOnly ? this.props.onIntakeChanged(illContactsSection) : null
                }
                visitType={visitType}
              />
            </InfoEditWrapper>
          )}

        {visitType === "covid_at_home_pcr" && (
          <InfoEditWrapper
            data={getIntakeHistory(["testingReason"])}
            onCancel={onCancelIntakeHistory(testingReasonSection)}
            readOnly={this.props.readOnly}
            onSave={() => onSaveIntakeHistory(["testingReason"], testingReasonSection)}
            title={testingReasonSection}
            unsavedSections={this.props.unsavedSections}
          >
            <TestingReason
              testingReason={intake.testingReason}
              onChanged={
                !this.props.readOnly ? this.props.onIntakeChanged(testingReasonSection) : null
              }
              // @ts-expect-error ts-migrate(7006) FIXME: Parameter implicitly has an 'any' type.
              visitType={visitType}
            />
          </InfoEditWrapper>
        )}
        {visitType != "rash_and_allergy" &&
          visitType !== "contraceptives" &&
          visitType !== "rxrenewal" &&
          visitType !== "rx_request" &&
          visitType !== "nursing_home" && (
            <InfoEditWrapper
              data={getIntakeHistory(["medicationsTried"])}
              onCancel={onCancelIntakeHistory(medicationsTriedSection)}
              readOnly={this.props.readOnly}
              onSave={() => onSaveIntakeHistory(["medicationsTried"], medicationsTriedSection)}
              title={medicationsTriedSection}
              unsavedSections={this.props.unsavedSections}
            >
              <MedicationsTried
                medicationsTried={intake.medicationsTried}
                visitType={visitType}
                onChanged={
                  !this.props.readOnly ? this.props.onIntakeChanged(medicationsTriedSection) : null
                }
                medicationsTriedFieldName={"medicationsTried"}
              />
            </InfoEditWrapper>
          )}

        {visitType == "rash_and_allergy" && (
          <InfoEditWrapper
            data={getIntakeHistory(["allergyEyesMedicationsTried"])}
            onCancel={onCancelIntakeHistory(eyeAllergyMedicationsTriedSection)}
            readOnly={this.props.readOnly}
            onSave={() =>
              onSaveIntakeHistory(
                ["allergyEyesMedicationsTried"],
                eyeAllergyMedicationsTriedSection,
              )
            }
            title={eyeAllergyMedicationsTriedSection}
            unsavedSections={this.props.unsavedSections}
          >
            <MedicationsTried
              medicationsTried={intake.allergyEyesMedicationsTried}
              visitType="allergy-eyes"
              onChanged={
                !this.props.readOnly
                  ? this.props.onIntakeChanged(eyeAllergyMedicationsTriedSection)
                  : null
              }
              medicationsTriedFieldName={"allergyEyesMedicationsTried"}
            />
          </InfoEditWrapper>
        )}

        {visitType == "rash_and_allergy" && (
          <InfoEditWrapper
            data={getIntakeHistory(["allergyNoseMedicationsTried"])}
            onCancel={onCancelIntakeHistory(noseAllergyMedicationsTriedSection)}
            readOnly={this.props.readOnly}
            onSave={() =>
              onSaveIntakeHistory(
                ["allergyNoseMedicationsTried"],
                noseAllergyMedicationsTriedSection,
              )
            }
            title={noseAllergyMedicationsTriedSection}
            unsavedSections={this.props.unsavedSections}
          >
            <MedicationsTried
              medicationsTried={intake.allergyNoseMedicationsTried}
              visitType="allergy-nose"
              onChanged={
                !this.props.readOnly
                  ? this.props.onIntakeChanged(noseAllergyMedicationsTriedSection)
                  : null
              }
              medicationsTriedFieldName={"allergyNoseMedicationsTried"}
            />
          </InfoEditWrapper>
        )}

        {visitType == "rash_and_allergy" && (
          <InfoEditWrapper
            data={getIntakeHistory(["allergySkinMedicationsTried"])}
            onCancel={onCancelIntakeHistory(skinAllergyMedicationsTriedSection)}
            readOnly={this.props.readOnly}
            onSave={() =>
              onSaveIntakeHistory(
                ["allergySkinMedicationsTried"],
                skinAllergyMedicationsTriedSection,
              )
            }
            title={skinAllergyMedicationsTriedSection}
            unsavedSections={this.props.unsavedSections}
          >
            <MedicationsTried
              medicationsTried={intake.allergySkinMedicationsTried}
              visitType="allergy-skin"
              onChanged={
                !this.props.readOnly
                  ? this.props.onIntakeChanged(skinAllergyMedicationsTriedSection)
                  : null
              }
              medicationsTriedFieldName={"allergySkinMedicationsTried"}
            />
          </InfoEditWrapper>
        )}

        {visitType == "rash_and_allergy" && (
          <InfoEditWrapper
            data={getIntakeHistory(["allergyLungsMedicationsTried"])}
            onCancel={onCancelIntakeHistory(lungAllergyMedicationsTriedSection)}
            readOnly={this.props.readOnly}
            onSave={() =>
              onSaveIntakeHistory(
                ["allergyLungsMedicationsTried"],
                lungAllergyMedicationsTriedSection,
              )
            }
            title={lungAllergyMedicationsTriedSection}
            unsavedSections={this.props.unsavedSections}
          >
            <MedicationsTried
              medicationsTried={intake.allergyLungsMedicationsTried}
              visitType="allergy-lungs"
              onChanged={
                !this.props.readOnly
                  ? this.props.onIntakeChanged(lungAllergyMedicationsTriedSection)
                  : null
              }
              medicationsTriedFieldName={"allergyLungsMedicationsTried"}
            />
          </InfoEditWrapper>
        )}

        {intake.conditionSpecificIntake && !isEmpty(intake.conditionSpecificIntake.answers) && (
          <InfoEditWrapper
            data={getIntakeHistory(["conditionSpecificIntake"])}
            onCancel={onCancelIntakeHistory(conditionSpecificIntakeSection)}
            onSave={() =>
              onSaveIntakeHistory(["conditionSpecificIntake"], conditionSpecificIntakeSection)
            }
            title={conditionSpecificIntakeSection}
            unsavedSections={this.props.unsavedSections}
          >
            <OtherIntake
              conditionSpecificIntake={intake.conditionSpecificIntake}
              visitType={visitType}
              onChanged={
                !this.props.readOnly
                  ? this.props.onIntakeChanged(conditionSpecificIntakeSection)
                  : null
              }
            />
          </InfoEditWrapper>
        )}

        {visitType == "rash_and_allergy" &&
          intake.allergySystems.lungs &&
          intake.allergyLungsHistory && (
            <LungAllergyHistory lungHistory={intake.allergyLungsHistory} visitType={visitType} />
          )}
        {visitType === "nursing_home" && (
          <PertinentMedications
            onMedicationsChange={onMedicationsChange}
            notedMedications={(this.props.note || {}).medications}
            medications={this.state.pccMedications}
            covid19Protocol={this.props.covid19Protocol}
            pertinentMedicationsNotes={this.props.pertinentMedicationsNotes}
            // @ts-expect-error ts-migrate(7006) FIXME: Parameter implicitly has an 'any' type.
            covidProtocolChange={this.props.covidProtocolChange}
            // @ts-expect-error ts-migrate(7006) FIXME: Parameter implicitly has an 'any' type.
            covidNoteChange={this.props.covidNoteChange}
          />
        )}
        {visitType !== "rxrenewal" && (
          <div className="block-section content-block">
            <ReviewOfSystems
              reviewOfSystems={intake.reviewOfSystemsKeys}
              pertinentNegatives={intake.rosNegatives}
              onChanged={onIntakeChanged(medicalIntakeFields, reviewOfSystemsSection)}
              symptomDetails={intake.symptomDetailsKeys}
              visitType={visitType}
              perSystemTitles={true}
              catalog={this.state.catalog}
              additionalRos={intake.reviewOfSystemsWritten}
              ROSGotSSC={intake.reviewOfSystemsGotSSC}
              symptomsDetailField={"symptomDetailsKeys"}
              symptomByStystemField={"reviewOfSystemsKeys"}
              readOnly={this.props.readOnly}
            />
          </div>
        )}

        {visitType == "rash_and_allergy" && intake.allergyEnvironmentalEffect && (
          <InfoEditWrapper
            data={getIntakeHistory(["allergyEnvironmentalEffect"])}
            onCancel={onCancelIntakeHistory(allergyEnvironmentalEffectsSection)}
            readOnly={this.props.readOnly}
            onSave={() =>
              onSaveIntakeHistory(
                ["allergyEnvironmentalEffect"],
                allergyEnvironmentalEffectsSection,
              )
            }
            title={allergyEnvironmentalEffectsSection}
            unsavedSections={this.props.unsavedSections}
          >
            <AllergyEnvironmentalEffect
              allergyEnv={intake.allergyEnvironmentalEffect}
              onChanged={
                !this.props.readOnly
                  ? this.props.onIntakeChanged(allergyEnvironmentalEffectsSection)
                  : null
              }
              visitType={visitType}
            />
          </InfoEditWrapper>
        )}

        {visitType == "rash_and_allergy" && intake.allergySocialHistory && (
          <InfoEditWrapper
            data={getIntakeHistory(["allergySocialHistory"])}
            onCancel={onCancelIntakeHistory(allergySocialHistorySection)}
            readOnly={this.props.readOnly}
            onSave={() =>
              onSaveIntakeHistory(["allergySocialHistory"], allergySocialHistorySection)
            }
            title={allergySocialHistorySection}
            unsavedSections={this.props.unsavedSections}
          >
            <AllergySocialHistory
              allergySocialHistory={intake.allergySocialHistory}
              onChanged={
                !this.props.readOnly
                  ? this.props.onIntakeChanged(allergySocialHistorySection)
                  : null
              }
              visitType={visitType}
            />
          </InfoEditWrapper>
        )}

        {visitType == "rxrenewal" && (
          <ContentBlock title="Prescription">
            <Prescriptions
              prescriptionDetails={prescriptionDetails}
              Images={
                !!this.props.details && !!this.props.details.images ? this.props.details.images : []
              }
              visitType={visitType}
            />
          </ContentBlock>
        )}
        {!this.props.readOnly &&
          visitType !== "nursing_home" &&
          visitType !== "rxrenewal" &&
          visitType !== "rx_request" && (
            <ContentBlock title="Patient intake notes">
              <Input
                type="textarea"
                disabled={this.props.readOnly}
                name="patientIntakeNotes"
                onChange={onNoteChanged}
                value={patientIntakeNotes}
              />
            </ContentBlock>
          )}
      </div>
    );
  }
}
