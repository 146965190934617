import React, { useEffect, useState } from "react";
import { Row, Col, Label } from "reactstrap";
import "./Scheduling.scss";
import moment, { Moment } from "moment";
import BackArrow from "../../images/smBackArrow.svg";
import ForwardArrow from "../../images/ForwardArrow.svg";
import HeatMap from "./HeatMap";
import StateSelector, { STATES } from "../StateSelector";
import { TIMEZONES_BY_CITY } from "../../constants/Providers";

import api from "../../api";
import { connect } from "react-redux";
import Select from "react-select";
import { errorLogger } from "../../utils";

const WEEK_VIEW = "Week View";
const DAY_VIEW = "Day View";
const AVAILABILITY = "Availability";
const SCHEDULED_APPOINTMENTS = "Scheduled";
const OPEN_APPOINTMENTS = "Open";
const CONFLICT_APPOINTMENTS = "Conflict";

const allPracticesOption = {label:"All Practices", value: "all"}
const states = STATES.map((el) => ({ label: el.value, value: el.value }));

const InfoPanel = () => {
  return (
    <div className="timeZone-dropdown w-25 select mr-3 mt-2">
      <Select
        defaultValue={TIMEZONES_BY_CITY[0]}
        options={TIMEZONES_BY_CITY}
        className="timezone-select"
        classNamePrefix="timezone-select"
      />
    </div>
  );
};

const ScheduledAppointments = ({ currentUser, practiceList }) => {
  const [dateObject, setDateObject] = useState<Moment>(moment());
  const [selectedDate, setSelectedDate] = useState<Moment>(moment());
  const [view, setView] = useState(WEEK_VIEW);
  const [currentView, setCurrentView] = useState(AVAILABILITY);

  const [selectedState, setSelectedState] = useState("");
  const [selectedPractices, setSelectedPractices] = useState([]);

  const [practiceOptions, setPracticeOptions] = useState([]);

  useEffect(() => {
    if (practiceList) {
      let practices = [];
      Object.keys(practiceList.items).map((s) => practices.push(...practiceList.items[s]));
      const formattedList = practices.map((item, index) => {
        return {label: item.name, value:item.id, fullData:item}
      })
      setPracticeOptions([...formattedList]);
    }
  }, [practiceList]);

  useEffect(() => {

    const options = currentUser?.medicalPractice;


    // setPracticeOptions()
  }, []);

  const handleDayMonthChange = (value) => {
    if (view === WEEK_VIEW) {
      // if next set date object to first day of next month
      if (value === "Next") {
        let newMonth = moment(dateObject).add(1, "M");
        // first day of month
        let cleanMonth = moment(newMonth).startOf("month");

        if (newMonth.format("MM/DD/YYYY") === moment().format("MM/DD/YYYY")) {
          // if next month is current month set date object to current date
          setDateObject(moment());
          setSelectedDate(moment());
        } else {
          setDateObject(cleanMonth);
          setSelectedDate(cleanMonth);
        }
      } else {
        let newMonth = moment(dateObject).subtract(1, "M");
        let cleanMonth = moment(newMonth).startOf("month");
        if (newMonth.format("MM/DD/YYYY") === moment().format("MM/DD/YYYY")) {
          setDateObject(moment());
          setSelectedDate(moment());
        } else {
          // if back set date object to first day of previous month
          setDateObject(cleanMonth);
          setSelectedDate(cleanMonth);
        }
      }
    } else {
      //if next set the selected date to next day
      if (value === "Next") {
        let newDay = moment(selectedDate).add(1, "day");
        setSelectedDate(newDay);
        setDateObject(newDay);
      } else {
        //  if back set the selected date to previous day
        let newDay = moment(selectedDate).subtract(1, "day");
        setSelectedDate(newDay);
        setDateObject(newDay);
      }
    }
  };

  const handleViewChange = (newView) => {
    setCurrentView(newView);
    // call api with new query
  };

  return (
    <div className="dashboard-container scheduling-view">
      <Row>
        <Col md="2">
          <div className="mt-5 mb-1 viewLabel">View</div>
          <div className="radioGroup">
            <div className="d-flex flex-row">
              <input
                type="radio"
                checked={currentView === AVAILABILITY}
                onChange={() => handleViewChange(AVAILABILITY)}
                className="mr-2"
              />
              <Label className="availability">Availability</Label>
            </div>
            <div className="d-flex flex-row">
              <input
                type="radio"
                checked={currentView === SCHEDULED_APPOINTMENTS}
                onChange={() => handleViewChange(SCHEDULED_APPOINTMENTS)}
                className="mr-2"
              />
              <Label className="open"> Scheduled Appointments </Label>
            </div>
          </div>

          <div className="mt-4">
            <div className="mb-2">Filter</div>

            <div className="d-flex flex-column">
              <div className="mt-4">
                <StateSelector
                  onChange={(e) => setSelectedState(e.target.value)}
                  value={selectedState}
                />
              </div>
              <div className="mt-4">
                <Select
                  id="qa-practice-select"
                  options={practiceOptions}
                  placeholder="All Practices"
                  onChange={(options) => setSelectedPractices(options)}
                  isSearchable={true}
                  isMulti
                  backspaceRemovesValue={false}
                  closeMenuOnSelect={false}
                  noOptionsMessage={() => null}
                />
              </div>
            </div>
          </div>
        </Col>
        <Col md="10">
          <div className="d-flex flex-row justify-content-between">
            <div className="d-flex ml-5">
              <h3 className="m-auto">
                {view === WEEK_VIEW
                  ? moment(dateObject).format("MMMM YYYY")
                  : moment(selectedDate).format("dddd MM/DD/YYYY")}
              </h3>{" "}
              <button className="arrows" onClick={() => handleDayMonthChange("Back")}>
                <BackArrow />
              </button>
              <button className="arrows ml-1" onClick={() => handleDayMonthChange("Next")}>
                <ForwardArrow />
              </button>
            </div>
            {InfoPanel()}
          </div>
          <HeatMap />
        </Col>
      </Row>
    </div>
  );
};
const mapStateToProps = (state) => ({
  currentUser: state.common.currentUser,
  practiceList: state.common.practiceList,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledAppointments);

