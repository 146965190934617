import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import get from "lodash.get";
import moment from "moment";
import { Row, Col, Table, Button } from "reactstrap";

import { ACTIVITY_SUMMARY } from "../../constants/actionTypes";
import "../../stylesheets/activityMonitor.scss";

import NavBar from "../Provider/NavBar";
import ActivityMonitorIcon from "../../images/ActivityMonitor.svg";
import api from "../../api";
import { STATES } from "../../constants/Providers";
import PageLoadSpinner from "../PageLoadSpinner";
import PatientList from "../WaitingRoom/PatientList";
import ForwardVisitModal from "../Provider/ForwardModal";
import { errorLogger } from "../../utils";
import { IProviderSavedEncounter } from "../../constants/Types";

const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: state.common.currentUser,
    activitySummary: state.monitoring,
    availablePatients: state.waitingRoom.available,
    pendingPatients: state.waitingRoom.pending,
    postponedPatients: state.waitingRoom.postponed,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateSummary: (payload) => dispatch({ type: ACTIVITY_SUMMARY, payload }),
});
const mockdata = [
  {
    "providerID": "provider-cbc2d0c6-5f10-4e53-814c-d5ae18b7eb6f",
    "status": "logged-in",
    "startTime": "2022-11-08T09:19:54.710875007Z",
    "endTime": "0001-01-01T00:00:00Z",
    "medicalPractice": "mp-8bf7bb34-4054-4c51-87da-8c3cfa7020e3",
    "presenceTimeLine": [
      {
        "updatedAt": "2022-11-08T09:19:54.710875007Z",
        "status": "logged-in",
        "trigger": "https://emrdev.reliantid.com"
      },
      {
        "updatedAt": "2022-11-08T12:00:15.213319826Z",
        "status": "available",
        "trigger": "Task"
      }
    ],
    "encounterStartTime": null,
    "updatedAt": "2022-11-08T09:19:55.603503136Z",
    "capabilities": {
      "demographic": [
        "child",
        "adolescent",
        "adult"
      ],
      "geographic": [
        "FL",
        "WA",
        "NY",
        "AZ",
        "CA",
        "CO",
        "CT",
        "DC",
        "GA",
        "HI",
        "DE",
        "IL",
        "ID",
        "IN",
        "AK",
        "AL",
        "TX"
      ],
      "linguistic": [
        "english",
        "spanish"
      ],
      "credentialedPayors": null
    },
    "providerName": "uriy voit, MD"
  },
  {
    "providerID": "provider-cbc2d0c6-5f10-4e53-814c-d5ae18b7eb6f",
    "status": "logged-in",
    "startTime": "2022-11-08T09:19:54.710875007Z",
    "endTime": "0001-01-01T00:00:00Z",
    "medicalPractice": "mp-8bf7bb34-4054-4c51-87da-8c3cfa7020e3",
    "presenceTimeLine": [
      {
        "updatedAt": "2022-11-08T09:19:54.710875007Z",
        "status": "logged-in",
        "trigger": "https://emrdev.reliantid.com"
      }
    ],
    "encounterStartTime": null,
    "updatedAt": "2022-11-08T09:19:55.603503136Z",
    "capabilities": {
      "demographic": [
        "child",
        "adolescent",
        "adult"
      ],
      "geographic": [
        "FL",
        "WA",
        "NY",
        "AZ",
        "CA",
        "CO",
        "CT",
        "DC",
        "GA",
        "HI",
        "DE",
        "IL",
        "ID",
        "IN",
        "AK",
        "AL",
        "TX"
      ],
      "linguistic": [
        "english",
        "spanish"
      ],
      "credentialedPayors": null
    },
    "providerName": "uriy voit, MD"
  },
  {
    "providerID": "provider-cbc2d0c6-5f10-4e53-814c-d5ae18b7eb6f",
    "status": "logged-in",
    "startTime": "2022-11-08T09:19:54.710875007Z",
    "endTime": "0001-01-01T00:00:00Z",
    "medicalPractice": "mp-8bf7bb34-4054-4c51-87da-8c3cfa7020e3",
    "presenceTimeLine": [
      {
        "updatedAt": "2022-11-08T09:19:54.710875007Z",
        "status": "logged-in",
        "trigger": "https://emrdev.reliantid.com"
      }
    ],
    "encounterStartTime": null,
    "updatedAt": "2022-11-08T09:19:55.603503136Z",
    "capabilities": {
      "demographic": [
        "child",
        "adolescent",
        "adult"
      ],
      "geographic": [
        "FL",
        "WA",
        "NY",
        "AZ",
        "CA",
        "CO",
        "CT",
        "DC",
        "GA",
        "HI",
        "DE",
        "IL",
        "ID",
        "IN",
        "AK",
        "AL",
        "TX"
      ],
      "linguistic": [
        "english",
        "spanish"
      ],
      "credentialedPayors": null
    },
    "providerName": "uriy voit, MD"
  },
  {
    "providerID": "provider-cbc2d0c6-5f10-4e53-814c-d5ae18b7eb6f",
    "status": "logged-in",
    "startTime": "2022-11-08T09:19:54.710875007Z",
    "endTime": "0001-01-01T00:00:00Z",
    "medicalPractice": "mp-8bf7bb34-4054-4c51-87da-8c3cfa7020e3",
    "presenceTimeLine": [
      {
        "updatedAt": "2022-11-08T09:19:54.710875007Z",
        "status": "logged-in",
        "trigger": "https://emrdev.reliantid.com"
      }
    ],
    "encounterStartTime": null,
    "updatedAt": "2022-11-08T09:19:55.603503136Z",
    "capabilities": {
      "demographic": [
        "child",
        "adolescent",
        "adult"
      ],
      "geographic": [
        "FL",
        "WA",
        "NY",
        "AZ",
        "CA",
        "CO",
        "CT",
        "DC",
        "GA",
        "HI",
        "DE",
        "IL",
        "ID",
        "IN",
        "AK",
        "AL",
        "TX"
      ],
      "linguistic": [
        "english",
        "spanish"
      ],
      "credentialedPayors": null
    },
    "providerName": "uriy voit, MD"
  }
]

const TableRow = (props) => {
  const item = props.item;
  const prevStatus = item.presenceTimeLine.length !==1 ? item.presenceTimeLine[item.presenceTimeLine.length - 1].status : false
  return (
    <tr key={props.idx}>
      <td>{item.providerName || "--"}</td>
      <td>{`${item.status} - Since ${moment(item.updatedAt).fromNow()}` || "-"} </td>
      <td>{"--"}</td>
      <td>{prevStatus ? `${prevStatus} - ${moment(item.updatedAt).format("MMM Do, h:mm a")}` : "--"}</td>
      <td className="text-right">{"--"}</td>
    </tr>
  );
};


const ActivitySummary = ({ updateSummary, activitySummary, currentUser, availablePatients, pendingPatients, }) => {
  const [states, setStates] = useState<string[]>([]);
  const [selectedState, setSelectedState] = useState("");
  const [list, setList] = useState([]);
  const [providerList, setProviderList] = useState([]);
  const [scribeList, setScribeList] = useState([]);
  const [onlinePatients, setOnlinePatients] = useState([]);
  const [offlinePatients, setOfflinePatients] = useState([]);
  const [patientsWithChat, setPatientsWithChat] = useState([]);
  const [encounterKey, setEncounterKey] = useState({});
  const [forwardModal, setForwardModal] = useState(false);
  const [provider, setProvider] = useState("");
  const [user, setSelectedUser] = useState<IProviderSavedEncounter>();

  const assignEncounter = async (referenceID) => {
    try {
      await api.Encounters.assign(referenceID, provider!);
      setForwardModal(false);
    } catch (error: any) {
      const status = error.response?.status;
      if (status === 422) {
        errorLogger(error)
      }
    }
  };

  const handleSelect = (provider: { target: { value: string } }) => {
    let providerID = provider?.target?.value;
    setProvider(providerID);
  };

  const availableVisits = [{location:"TX"}, {location:"TX"}, {location:"TX"}, {location:"AL"}]

  const showForwardModal = (item) => {
    setEncounterKey(item.EncounterKey);
    setForwardModal(true);
    setSelectedUser(item);
  };
  const isAvailable = (state) => {
    return availablePatients.filter(visitItem => visitItem.location === state)?.length
  }
  const splitAvailableBySection = () => {
    const filterIsOnline = availablePatients.filter((item) =>
      moment.duration(moment().diff(moment(item.patientLastSeen))).asSeconds() < 30 &&
      item.location === selectedState &&
      item.communicationWithChat !== true
    );
    const filterIsOffline = availablePatients.filter((item) =>
      moment.duration(moment().diff(moment(item.patientLastSeen))).asSeconds() > 30 &&
      item.location === selectedState &&
      item.communicationWithChat !== true
    );
    const filterIsChat = availablePatients.filter((item) =>
      item.location === selectedState &&
      item.communicationWithChat === true
    );
    setOnlinePatients(filterIsOnline);
    setOfflinePatients(filterIsOffline)
    setPatientsWithChat(filterIsChat)
  }
  const filterProvidersByState = () => {
    const filterByState = list.filter((item) => item.capabilities.geographic.includes(selectedState));
    setProviderList(filterByState)

    const filterByScribeState = list.filter((item) => item.providerScribingStates?.includes(selectedState));
    console.log(filterByScribeState);
    setScribeList(filterByScribeState)
  }
  const onDefaultStateChange = (item) => {
    setSelectedState(item)
    localStorage.setItem("activityMonitorState", item)
  }

  useEffect(() => {
    splitAvailableBySection()
    filterProvidersByState()

  }, [availablePatients, selectedState]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let data = await api.Monitoring.activityMonitoring();
        setList(data.activeProviders);
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
    const defaultState =  localStorage.getItem("activityMonitorState");
    setSelectedState(defaultState)

  }, []);


  return (
    <div>
      <NavBar />
      <ForwardVisitModal
        visible={forwardModal}
        onCancel={() => setForwardModal(false)}
        onAssign={assignEncounter}
        encounterID={encounterKey}
        handleSelect={handleSelect}
        selected={provider}
        state={user?.state || ""}
      />
      <div className="dashboard-container qa-activityMonitorContainer">
        <div className="dashboard-component align-content-stretch">
          <Row>
             {Object.keys(STATES).map((item,index) => (
               <Button
                 key={`stateBtn-${index}`}
                 className={`stateBtn ${isAvailable(item) ? "available" : ""} ${selectedState === item && "selected"}`}
                 outline
                 color="primary"
                 onClick={() => onDefaultStateChange(item)}
               >
                 {STATES[item]} {!!isAvailable(item) ? `(${isAvailable(item)})` : ""}
               </Button>
             ))}
          </Row>
        </div>
        <div className="dashboard-component">
          {selectedState ? (
            <Row>
              <Col md={6} className="provider-column">
                <div className="d-flex flex-column">
                  <h4>{STATES[selectedState]} Provider</h4>
                  <div className="d-flex flex-wrap align-items-center justify-content-between">
                    {providerList.length > 0 ? (
                      <Table>
                        <thead>
                        <tr>
                          <th width="20%">Name</th>
                          <th width="20%">Status</th>
                          <th width="20%">Next Scheduled Appointment</th>
                          <th width="20%">Previous Status</th>
                          <th className="text-right" width="20%">On Schedule Next</th>
                        </tr>
                        </thead>
                        <tbody>
                        {providerList.map((item, idx) => (
                          <TableRow
                            key={`providerRow-${idx}`}
                            item={item}
                          />
                        ))
                        }
                        </tbody>
                      </Table>
                    ) : (
                      <div className="dashboard-component disabled">
                        <h2 className="text-center">No available providers in this state</h2>
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex flex-column">
                  <h4>{STATES[selectedState]} Scribing Providers</h4>
                  <div className="d-flex flex-wrap align-items-center justify-content-between">
                    {scribeList.length > 0 ? (
                      <Table>
                        <thead>
                        <tr>
                          <th width="20%">Name</th>
                          <th width="20%">Status</th>
                          <th width="20%">Next Scheduled Appointment</th>
                          <th width="20%">Previous Status</th>
                          <th className="text-right" width="20%">On Schedule Next</th>
                        </tr>
                        </thead>
                        <tbody>
                        {scribeList.map((item, idx) => (
                          <TableRow
                            key={`scribeRow-${idx}`}
                            item={item}
                          />
                        ))
                        }
                        </tbody>
                      </Table>
                    ) : (
                      <div className="dashboard-component disabled">
                        <h2 className="text-center"> No available providers in this state</h2>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
              <Col md={6} className="patient-queue-column">
                <h4 className="">{STATES[selectedState]} Waiting Room</h4>
                <div
                  className={`dashboard-component align-content-stretch ${onlinePatients.length <= 0 && "disabled"}`}>
                  {onlinePatients.length > 0 ? <h3>Online video visits</h3> :
                    <h3 className="text-center">No available Online visits</h3>}
                  <PatientList showForwardModal={showForwardModal} activityMonitor={true} items={onlinePatients} />
                  <PatientList activityMonitor={true} items={pendingPatients} />
                </div>
                <div
                  className={`dashboard-component align-content-stretch ${patientsWithChat.length <= 0 && "disabled"}`}>
                  {patientsWithChat.length > 0 ? <h3>Chat visits</h3> :
                    <h3 className="text-center">No available Chat visits</h3>}
                  <PatientList showForwardModal={showForwardModal} activityMonitor={true} items={patientsWithChat} />
                </div>
                <div
                  className={`dashboard-component align-content-stretch ${offlinePatients.length <= 0 && "disabled"}`}>
                  {offlinePatients.length > 0 ? <h3>Offline video visits</h3> :
                    <h3 className="text-center">No available Offline visits</h3>}
                  <PatientList showForwardModal={showForwardModal} activityMonitor={true} items={offlinePatients} />
                </div>
              </Col>
            </Row>
          ) : (
            <h2 className="text-center">Please select a state to see available providers and encounters</h2>
          )
          }
        </div>
      </div>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ActivitySummary);
