import React, { useState, useEffect } from 'react';
import NavBar from '../Provider/NavBar';
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Label } from 'reactstrap';
import "./Scheduling.scss";
import moment, { Moment } from 'moment';
import BackArrow from '../../images/smBackArrow.svg'
import ForwardArrow from "../../images/ForwardArrow.svg"
import HeatMap from "./HeatMap"
import { STATES } from "../StateSelector";
import { TIMEZONES_BY_CITY } from '../../constants/Providers';
import Select from 'react-select'
import api from '../../api';
import Conflicts from './ConflictAppointments';
import Octicon, { ChevronLeft } from '@githubprimer/octicons-react';

const WEEK_VIEW = "Week View";
const DAY_VIEW = "Day View";
const AVAILABILITY = "Availability"
const BOOKED_APPOINTMENTS = "Booked"
const OPEN_APPOINTMENTS = "Open"
const CONFLICT_APPOINTMENTS = "Conflict"

const customStyles = {
    control: () => ({
        borderRadius: 3,
        width: 112,
        display: "flex",
        // boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        border: "1px solid #DEDEDE",
        "font-weight": "bold",
    })
}

const filtersStyles = {
    control: () => ({
        borderRadius: 3,
        width: 200,
        display: "flex",
        // boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        border: "1px solid #DEDEDE",
        "font-weight": "bold",
    })
}
const setSelectedValue = (options: { label: string, value: string }[], values: string[]) => {
    return options.filter((el) => values?.includes(el.value)) || null
}
const SchedulingView = ({ }) => {
    const [errors, setErrors] = useState(true)
    const [showConflicts, setShowConflicts] = useState(false)
    const [dateObject, setDateObject] = useState<Moment>(moment())
    const [selectedDate, setSelectedDate] = useState<Moment>(moment());

    const [timeZone, setTimeZone] = useState(TIMEZONES_BY_CITY[0])
    const [tzDropdownOpen, setTzDropdownOpen] = useState(false)

    const [view, setView] = useState(WEEK_VIEW)
    const [currentView, setCurrentView] = useState(AVAILABILITY)
    const [query, setQuery] = useState<{ states: null | string[], providers: null | string[], practices: null | string[] }>({ states: null, providers: null, practices: null })
    const [providerOptions, setProviderOptions] = useState<string[]>([])
    const [practiceOptions, setPracticeOptions] = useState<{ label: string, value: string }[]>([])

    useEffect(() => {
        loadProviders()
        loadPractices()
    }, [])

    const loadPractices = async () => {
        try {
            let result = await api.Providers.listPractices();
            let formatted = Object.values(result.items).flat().map((p) => { return { label: p.name, value: p.id } }) || []
            setPracticeOptions(formatted)
        } catch (err) {
            console.log("ERROR LOADING PRACTICES: ::", err)
        }
    }

    const loadProviders = async () => {

    }

    const handleDayMonthChange = (value) => {
        if (view === WEEK_VIEW) {
            // if next set date object to +7 days
            if (value === "Next") {
                let nextWeek = moment(dateObject).add(7, 'd')
                setDateObject(nextWeek)
            } else {
                let prevWeek = moment(dateObject).subtract(7, 'd')
                setDateObject(prevWeek)
            }
        } else {
            //if next set the selected date to next day
            if (value === "Next") {
                let newDay = moment(selectedDate).add(1, "day");
                setSelectedDate(newDay);
                setDateObject(newDay);
            } else {
                //  if back set the selected date to previous day
                let newDay = moment(selectedDate).subtract(1, "day");
                setSelectedDate(newDay);
                setDateObject(newDay);
            }
        }
    }

    const toggleTZ = () => setTzDropdownOpen(!tzDropdownOpen);

    const handleTimeZoneChange = (value) => {
        setTimeZone(value);
    };

    const handleViewChange = (newView) => {
        setCurrentView(newView)
        // call api with new query
    }

    const handleChange = (value: { label: string, value: string }[], meta: any) => {

        let queryCopy = { ...query }
        const values = value.map((el) => el.value) || []
        queryCopy[meta.name] = values
        //api call for selection
        setQuery(queryCopy)
    }


    return (
        <div>
            <NavBar />
            <div className='dashboard-container new-format scheduling-view'>
                <Row className='mb-3'>
                    <Col md="2">
                        {!showConflicts && <div className="d-flex flex-column">
                            <button className="settings-btn">Settings</button>
                            {errors && <span className='conflict-error' onClick={() => setShowConflicts(!showConflicts)}>Conflict Appointments Exist</span>}
                        </div>}
                    </Col>
                    <Col md="10">
                        <div className='d-flex flex-row justify-content-between'>

                            {!showConflicts ? <div className="d-flex ml-5">


                                <>
                                    <h3 className="m-auto">
                                        {view === WEEK_VIEW
                                            ? `${moment(dateObject).format("MMM DD")} - ${moment(dateObject).add(7, 'd').format("MMM DD")}`
                                            : moment(selectedDate).format("dddd MM/DD/YYYY")}
                                    </h3>{" "}
                                    <button className="arrows" onClick={() => handleDayMonthChange("Back")}>
                                        <BackArrow />
                                    </button>
                                    <button className="arrows ml-1" onClick={() => handleDayMonthChange("Next")}>
                                        <ForwardArrow />
                                    </button>
                                </>

                            </div>
                                :
                                <div className='d-flex ml-2'>
                                    <h3><span onClick={() => setShowConflicts(!showConflicts)} className="mr-3 backBtn">{`<`}</span>Appointment Manager / Conflicts</h3>
                                </div>}
                            <div className='d-flex flex-row justify-content-between'>
                                {!showConflicts &&
                                    <>
                                        <Label className='pt-3'>{WEEK_VIEW}
                                            <input
                                                type='radio'
                                                checked={view === WEEK_VIEW}
                                                onChange={() => setView(WEEK_VIEW)}
                                                className="dayWeek-radio mr-2 ml-2"
                                            />
                                        </Label>

                                        <Label className="mr-5 mb-0 pt-3">State View
                                            <input
                                                type='radio'
                                                checked={view === DAY_VIEW}
                                                onChange={() => setView(DAY_VIEW)}
                                                className="dayWeek-radio mr-2 ml-2"
                                            />
                                        </Label>
                                    </>
                                }
                                <label className='pt-3 mr-2'>Time Zone Display:</label>
                                <div className="mt-1 mr-4 ">
                                    <Select
                                        value={timeZone}
                                        options={TIMEZONES_BY_CITY}
                                        className="timezone-select"
                                        classNamePrefix="timezone-select"
                                        styles={customStyles}
                                    />
                                </div>
                            </div>

                        </div>
                    </Col>

                </Row>
                <Row>
                    <Col md="2">
                        <div className='mt-5 mb-1 viewLabel'>View</div>
                        <div className='radioGroup'>
                            <div className='d-flex flex-row'>
                                <input
                                    type="radio"
                                    checked={currentView === AVAILABILITY}
                                    onChange={() => handleViewChange(AVAILABILITY)}
                                    className="mr-2"
                                />
                                <Label className='availability'>Provider Availability</Label>
                            </div>
                            <div className='d-flex flex-row'>
                                <input
                                    type="radio"
                                    checked={currentView === OPEN_APPOINTMENTS}
                                    onChange={() => handleViewChange(OPEN_APPOINTMENTS)}
                                    className="mr-2"
                                />
                                <Label className='open'> Open Appointments </Label>
                            </div>
                            <div className='d-flex flex-row'>
                                <input
                                    type="radio"
                                    checked={currentView === BOOKED_APPOINTMENTS}
                                    onChange={() => handleViewChange(BOOKED_APPOINTMENTS)}
                                    className="mr-2"
                                />
                                <Label className='booked'> Booked Appointments </Label>
                            </div>
                        </div>

                        <div className='mt-4'>
                            <div className='mb-2'>Filter</div>

                            <div className='d-flex flex-column'>
                                <div className='mt-4'>
                                    <Select
                                        options={STATES}
                                        onChange={handleChange as any}
                                        value={setSelectedValue(STATES, query?.states || [])}
                                        styles={filtersStyles}
                                        isMulti
                                        isClearable
                                        placeholder='Select State'
                                        name="states"
                                    />
                                </div>
                                <div className='mt-4'>
                                    {/* <Select
                                        options={providerOptions}
                                        onChange={handleChange as any}
                                        value={setSelectedValue(providerOptions, query?.providers || [])}
                                        styles={filtersStyles}
                                        isMulti
                                        isClearable
                                        placeholder='Select Provider'
                                        name="providers"
                                    /> */}
                                </div>
                                <div className='mt-4'>
                                    <Select
                                        options={practiceOptions}
                                        onChange={handleChange as any}
                                        value={setSelectedValue(practiceOptions, query?.practices || [])}
                                        styles={filtersStyles}
                                        isMulti
                                        isClearable
                                        placeholder='Select Practice'
                                        name="practices"
                                    />
                                </div>
                            </div>

                        </div>
                    </Col>
                    <Col md="10">
                        <div style={{ height: "100vh" }}>
                            {!showConflicts ? <HeatMap view={view} zone={timeZone} /> : <Conflicts />}
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default SchedulingView