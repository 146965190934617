import React, { useState } from "react";
import { Table } from "reactstrap";

const DATA = [
    {
        patient: "John Doe",
        state: "TX",
        Practice: "Hy-Vee Rx Request",
        phone: "(555)-555-5555",
        provider: "Provider, DO",
        conflictTime: "Monday March 23, 2022 6:30am Central Time"
    },
    {
        patient: "John Doe",
        state: "TX",
        Practice: "Hy-Vee Rx Request",
        phone: "(555)-555-5555",
        provider: "Provider, DO",
        conflictTime: "Monday March 23, 2022 6:30am Central Time"
    },
    {
        patient: "John Doe",
        state: "TX",
        Practice: "Hy-Vee Rx Request",
        phone: "(555)-555-5555",
        provider: "Provider, DO",
        conflictTime: "Monday March 23, 2022 6:30am Central Time"
    },
    {
        patient: "John Doe",
        state: "TX",
        Practice: "Hy-Vee Rx Request",
        phone: "(555)-555-5555",
        provider: "Provider, DO",
        conflictTime: "Monday March 23, 2022 6:30am Central Time"
    }
]

const Conflicts = () => {

    const [openCard, setOpenCard] = useState<number | null>(null)

    return (
        <>
            <div className="conflictsContainer">
                {
                    DATA.map((item, key) => {
                        return (
                            <div className="conflictCard mt-3 mb-3 ml-2">
                                <div className="d-flex flex-row mb-2 justify-content-between">
                                    <div className="d-flex">
                                        <div className="mr-3 patientName">{item.patient}</div>
                                        <div className="mr-3">{item.state}</div>
                                        <div className=" patientPhone">{item.phone}</div>
                                    </div>
                                    <div className="d-flex sendReschedule">
                                        Send Cancelation with Reschdule invite
                                    </div>
                                </div>
                                <div className="d-flex flex-row justify-content-between">
                                    <div className="d-flex">
                                        <div className="mr-3 providerName">{item.provider}</div>
                                        <div>{item.conflictTime}</div>
                                    </div>
                                    {/* <div className="d-flex newTime" onClick={() => setOpenCard(key)}>
                                        Find a New Time
                                    </div> */}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}

export default Conflicts