import React, { useEffect, useState, useRef, Fragment } from "react"
import Select from "react-select";
import { Creatable } from "react-select";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import api from "../../api"
import { Table } from "reactstrap";
import MedicationsApiSelect from "./MedicationSelect"
import { IMedicationDetails, IDefaultMedication } from "../../constants/Types";
import { toast } from "react-toastify";
import ConfirmExitModal from "./ConfirmExitModal";

const defaultMedication: IDefaultMedication = {
    Active: true,
    Condition: null,
    DaysSupply: 0,
    Doseform: null,
    ProductName: null,
    Protocol: null,
    Quantity: null,
    RXCUI: null,
    ReferenceNumber: null,
    Refills: null,
    Route: null,
    Unit: null,
    UsageInstructions: null,
    NDC: null,
    Strength: null,
    ProductNameWithRoute: null,
    Kind: null,
    ID: null,
    PartnerPrice: null,
    PartnerProductName: null,
    PartnerServiceID: null,
    PartnerDosage: null,
    PartnerMedicationRouteType: null,
    PartnerDefaultStatus: null,
    PartnerHiddenStatus: null,
    PartnerChangeNotes: null,
    PartnerField9: null,
    PartnerField10: null,
}

const tableFields = [
    { label: "Condition", field: "Condition" },
    { label: "Product Name", field: "ProductName" },
    { label: "Product Name With Route", field: "ProductNameWithRoute" },
    { label: "Strength", field: "Strength" },
    { label: "RXCUI", field: "RXCUI" },
    { label: "NDC", field: "NDC" },
    { label: "Route", field: "Route" },
    { label: "Doseform", field: "Doseform" },
    { label: "Quantity", field: "Quantity" },
    { label: "Unit", field: "Unit" },
    { label: "Refills", field: "Refills" },
    { label: "Days Supply", field: "DaysSupply" },
    { label: "Usage Instructions", field: "UsageInstructions" },
    { label: "Protocol", field: "Protocol" },
    { label: "Reference Number", field: "ReferenceNumber" },
    { label: "Active", field: "Active" },
    { label: "Partner Price", field: "PartnerPrice" },
    { label: "Partner Product Name", field: "PartnerProductName" },
    { label: "Partner Service ID", field: "PartnerServiceID" },
    { label: "Partner Dosage", field: "PartnerDosage" },
    { label: "Partner Medication Route + Type", field: "PartnerMedicationRouteType" },
    { label: "Partner Default Status", field: "PartnerDefaultStatus" },
    { label: "Partner Hidden Status", field: "PartnerHiddenStatus" },
    { label: "Partner Change Notes", field: "PartnerChangeNotes" },
    { label: "Partner Field 9", field: "PartnerField9" },
    { label: "Partner Field 10", field: "PartnerField10" },
]

const customStyles = {
    container: (provided) => ({
        ...provided,
        borderRadius: "6px !important",
        border: "1px solid #94A4B9 !important"
    }),
    valueContainer: (provided) => ({
        ...provided,
        maxHeight: "40px",
        overflow: "hidden",
        backgroundColor: "#F2F5F9",
        borderRadius: "6px",
        padding: "5px 9px"
        // borderColor: "#94A4B9"
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: "#4A8FE7",
        backgroundColor: "#F2F5F9",
    }),
    clearIndicator: (provided) => ({
        ...provided,
        padding: "8px 2px",
        innerHeight: "9px",
        backgroundColor: "#F2F5F9",
        color: "#4A8FE7",
    }),
    indicatorSeparator: (provided) => ({
        color: "#4A8FE7",
        backgroundColor: "#F2F5F9",
    })
};

const errorStyles = {
    container: (provided) => ({
        ...provided,
        borderRadius: "6px !important",
        border: "2px solid #DC4545 !important"
    }),
    valueContainer: (provided) => ({
        ...provided,
        maxHeight: "40px",
        overflow: "hidden",
        backgroundColor: "#F2F5F9",
        borderRadius: "6px",
        // borderColor: "#94A4B9"
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: "#4A8FE7",
        backgroundColor: "#F2F5F9",
    }),
    clearIndicator: (provided) => ({
        ...provided,
        padding: "8px 2px",
        innerHeight: "9px",
        backgroundColor: "#F2F5F9",
        color: "#4A8FE7",
    }),
    indicatorSeparator: (provided) => ({
        color: "#4A8FE7",
        backgroundColor: "#F2F5F9",
    })
};

const medSupplyOptions: { label: string, value: string }[] = [
    { label: "Medication", value: "Medication" },
    { label: "Supply", value: "Supply" }
]

const activeOptions = [
    { label: "Yes", value: true },
    { label: "No", value: false }
]

const medRequired = ["Condition", "ProductName", "Unit", "Strength", "Quantity", "Refills", "DaysSupply"]
const supplyRequired = ["Condition", "ProductName", "Quantity", "Refills", "DaysSupply"]

const formatOption = (item: string) => ({ value: item, label: item })

const setSelectInputValue = (
    value: string | boolean,
    variants: { label: string; value: string | boolean }[],
) => {
    return variants.find((el) => el.value === value) || null;
};

const AddOrEdit = ({ editing, adding, copy, medication, setMedication, handleUpdate, setShowConfirmExit }) => {
    // const [medChanged, setMedChanged] = useState(defaultMedication)
    const [medOrSupply, setMedOrSupply] = useState<string>("Medication")
    const [error, setError] = useState<string[]>([])
    const [details, setDetails] = useState<IMedicationDetails | null>(null)
    const [conditionOptions, setConditionOptions] = useState<{ label: string, value: string }[] | []>([])
    const [unitOptions, setUnitOptions] = useState<{ label: string, value: string }[] | []>([])
    const [strengthList, setStrengthList] = useState<{ label: string, value: string }[] | []>([])

    const bodyRef = useRef(document.querySelector("body"));
    // for edit
    useEffect(() => {
        if (medication && (editing || copy)) {
            let condition = medication?.Condition?.toString()
            // setMedChanged({ ...medication, Condition: condition })
            setMedication({ ...medication, Condition: condition })
            loadStrengths(medication?.ProductName, medication?.ProductNameWithRoute)
        }

    }, [])
    // get conditions/units onLoad
    useEffect(() => {
        const fConditions = async () => {
            try {
                let result = await api.Catalog.familyHealthConditions()
                let conditions = result?.items?.map((item) => ({ value: item, label: item })) || []
                setConditionOptions(conditions)
            } catch (err) { }
        }

        const fUnits = async () => {
            try {
                let result = await api.MedicalProfile.medicationUnits()
                let units = result.items.map((item) => ({ label: item.SingularOrPlural, value: item?.StandardDispenseUnitTypeID?.toString() })) || []
                setUnitOptions(units)
            } catch (err) { }
        }
        fConditions()
        fUnits()
    }, []);

    const loadProductNameOptions = async (text: string, getRXCUI?: string) => {
        let result: any = null
        // if adding or editing check if we have product name and route 
        let namePresent = medication?.ProductName && medication?.ProductNameWithRoute
        if (text?.length > 2 && !namePresent) {
            try {
                if (medOrSupply === "Medication") {
                    result = await api.MedicalProfile.basicMedicationSearch(text)

                } else {
                    result = await api.MedicalProfile.supplySearch(text)

                }

            } catch (err) { }
        }

        const options = result?.items || []
        if (getRXCUI === "invalidStrength") {
            let match = options.find((item) => item?.Name === text && item?.NameWithRouteDoseForm === medication?.ProductNameWithRoute)

            setMedication({ ...medication, RXCUI: match?.RxCUI })
        } else {
            if (medOrSupply === "Medication") {
                return !!result?.items && options.map((item, idx) => ({ value: `${item.Name} ${idx}`, label: item.NameWithRouteDoseForm }))
            } else {
                return !!result?.items && options.map((item) => ({ value: item.Name, label: item.Name }))
            }
        }

    }


    const loadStrengths = async (medName: string, medNameWithRoute: string) => {
        const valueClean = medName?.split(" ")
        try {
            let data = await api.MedicalProfile.medicationDoseSearch(valueClean[0]);
            // loop through the dose data and if data?.items?.name === ProductNameWithRoute 
            const found = data?.items?.find((item) => item?.Name === medNameWithRoute);
            const strengths = found?.Strength && found?.Strength?.map((el) => ({ label: el.Strength, value: el.NDC })) || []
            setStrengthList(strengths)
        } catch (err) { }

    }

    const loadDetails = async (strength: string) => {
        try {
            let result = await api.MedicalProfile.medicationDetailsSearch(medication?.ProductNameWithRoute || "", strength || "")
            setDetails(result)
            const unitByID = unitOptions.length && unitOptions.find((unit) => Number(unit?.value) === result?.DispenseUnitId)
            setMedication({ ...medication, RXCUI: result?.RxCUI, Doseform: result.DoseForm, NDC: result?.NDC, Route: result?.Route, Unit: unitByID?.label })
        } catch (err) { }
    }

    const handleProductNameChange = (item: { label: string, value: string }) => {
        let medReset = { ...medication, Unit: null, RXCUI: null, NDC: null, Strength: null }
        // setMedChanged(medReset)
        setMedication(medReset)
        setDetails(null)
        selectInputHandler(item, "ProductName")
        loadStrengths(item?.value, item?.label)
    }

    const handleCancel = () => {
        setShowConfirmExit(true)
    }

    const handleSave = () => {
        let required = medOrSupply === "Medication" ? medRequired : supplyRequired;

        let missing: string[] = []
        for (const key of required) {

            const present = medication[key]
            if (required.includes(key) && !present) {
                missing.push(key)
            }
        }
        if (missing?.length > 0) {
            setError(missing)
            toast.error("Please complete required fields.")
        } else {
            // medications array aka Formularies
            const condition = Array.isArray(medication.Condition) ? medication.Condition : [medication.Condition]
            if (editing) {
                const medCopy = { ...medication }
                // send medication, & kind
                handleUpdate(medCopy)
            } else if (adding || copy) {
                const medClean = { ...medication, Condition: condition, DaysSupply: Number(medication?.DaysSupply), Quantity: Number(medication?.Quantity), Refills: Number(medication?.Refills) }
                handleUpdate(medClean)
            }

        }

    }

    const textInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMedication({ ...medication, [event.target.name]: event.target.value })
    }

    const selectInputHandler = (item, name) => {
        if (item || name === "Active") {
            if (name === "Unit") {
                setMedication({ ...medication, [name]: item.label })
            } else if (name === "Condition") {
                setMedication({ ...medication, [name]: [item.label] })

            } else if (name === "Strength") {
                const getNewDetails = details && details.Strength != item.label && details.RxCUI != medication.RXCUI
                if (item.label != "-" && (!details || getNewDetails)) {
                    loadDetails(item.label)
                } else if (medication?.ProductName && !medication.Strength) {
                    const productName = medication?.ProductName || ""
                    loadProductNameOptions(productName, "invalidStrength")
                }
                setMedication({ ...medication, [name]: item.label, NDC: item.value })
            } else if (name === "ProductName") {
                const valueClean = item.value.split(" ")
                setMedication({ ...medication, ProductName: valueClean[0] ? valueClean[0] : item.value, ProductNameWithRoute: item.label, Strength: null, RXCUI: null, Unit: null, NDC: null })
            } else if (name === "Unit") {
                setMedication({ ...medication, Unit: item.label })
            } else {
                setMedication({ ...medication, [name]: item.value })
            }

        } else {
            setMedication({ ...medication, [name]: null })
        }
    }

    const handleMedSupplyChange = (item) => {
        // if changed reset ProductName, Strength, Unit
        // setMedChanged(defaultMedication)
        setMedication(defaultMedication)
        // setStrength(null)
        setMedOrSupply(item.value)
    }
    const getStrengthValue = () => {
        //if there is a strength and a strengthList then find the strength in the List and return it
        if (medication?.Strength && strengthList.length) {
            let strenghtValue = strengthList.find((stre) => stre.label === medication?.Strength)
            // if there are no details call the endpoint to get all the missing values
            if (!details) {
                loadDetails(strenghtValue?.label || "")
            }
            return strenghtValue
        }
        //in the case there not being a strength initially
        else if (!medication?.Strength && strengthList.length) {
            let strenghtValue = strengthList[0]

            selectInputHandler(strenghtValue, "Strength")
            // if there are no details call the endpoint to get all the missing values
            if (!details) {
                loadDetails(strenghtValue?.label || "")
            }
            return strenghtValue
        }
        //when there is not a strength or a strength list return null
        // else {
        //     return null
        // }
    }

    const getUnitValue = () => {

        // if editing medication initially there will be a value for units which will be UnitOptions.label
        if (medication?.Unit && unitOptions.length) {
            return unitOptions.find((unit) => unit.label === medication?.Unit)
        } else if (!medication.Unit && unitOptions.length && details) {
            return unitOptions.find((unit) => Number(unit.value) === details.DispenseUnitId)
        } else {
            return null
        }
    }


    return (
        <>
            <hr />
            <label>Preview</label>
            <div className="formulary-edit-preview mt-1">
                <div className="preview-labels">
                    <Table>
                        <thead className="preview-header">
                            <tr >
                                {tableFields.map((item, idx) => {
                                    return (
                                        <th key={`tableFieldHeader-${idx}`}>{item.label}</th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody className="preview-body">
                            <tr>
                                {tableFields.map((item,idx) => {
                                    return (
                                        <Fragment key={`tableFieldBody-${idx}`}>
                                            <td>{medication[item.field] || "--"}</td>
                                        </Fragment>
                                    )
                                })}
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </div>
            <hr />
            <div className="mt-3"> {adding || copy ? "Add Formulary Item" : "Edit Formulary Item"}</div>
            <div>
                <div className="formulary-editor-block mt-3">
                    <div className="formulary-editor-select mr-5">
                        <label>Treatment Condition(s)</label>
                        <Creatable
                            options={conditionOptions}
                            onChange={(e) => selectInputHandler(e, "Condition")}
                            isClearable
                            isSearchable
                            name="Condition"
                            value={medication?.Condition ? formatOption(medication?.Condition) : null}
                            styles={error.includes("Condition") ? errorStyles : customStyles}
                        />
                    </div>
                    <div className="formulary-editor-select ml-5 mr-5">
                        <label>Medication or Supply</label>
                        <Select
                            options={medSupplyOptions}
                            onChange={handleMedSupplyChange}
                            styles={customStyles}
                            name="MedicationOrSupply"
                            value={formatOption(medOrSupply)}
                            menuPortalTarget={bodyRef.current}
                        />
                    </div>
                    <div className="formulary-editor-select mr-2 ml-5">
                        <label>Product Name and Route</label>
                        <MedicationsApiSelect
                            listSource={medOrSupply === "Medication" ? api.MedicalProfile.basicMedicationSearch : api.MedicalProfile.supplySearch}
                            loadOptions={loadProductNameOptions}
                            handleChange={handleProductNameChange}
                            isDisabled={!medication.Condition}
                            field={"ProductName"}
                            selectedItem={medication?.ProductName && medication?.ProductNameWithRoute ? { label: medication?.ProductNameWithRoute, value: medication?.ProductName } : null}
                            medChanged={medication}
                            editing={editing}
                            error={error.includes("ProductName")}
                            medOrSupply={medOrSupply}
                            copy={copy}
                        />
                    </div>

                </div>
                <div className="formulary-editor-block mt-4">
                    {medOrSupply === "Medication" ?
                        <div className="formulary-editor-select mr-5">
                            <label>Strength</label>
                            <Select
                                options={strengthList}
                                onChange={(e) => selectInputHandler(e, "Strength")}
                                isClearable={true}
                                isSearchable={true}
                                name="Strength"
                                value={getStrengthValue()}
                                styles={error.includes("Strength") ? errorStyles : customStyles}
                                isDisabled={!medication.ProductName}
                                menuPortalTarget={bodyRef.current}
                            />
                        </div>
                        :
                        <div className="formulary-editor-select mr-5 ">
                            <label>Strength</label>
                            <input
                                value={medication?.Strength || ""}
                                onChange={textInputHandler}
                                type="text" id="Strength"
                                className="quantity-input"
                                name="Strength"
                                placeholder="Strength"
                                disabled={!medication.ProductName}
                            />
                        </div>
                    }
                    {medOrSupply === "Medication" ? <div className="formulary-editor-select ml-5 mr-5">
                        <label>Unit</label>
                        <Select
                            options={unitOptions}
                            onChange={(e) => selectInputHandler(e, "Unit")}
                            isClearable
                            isSearchable
                            name="Unit"
                            value={getUnitValue()}
                            styles={error.includes("Unit") ? errorStyles : customStyles}
                            isDisabled={!medication.ProductName}
                            menuPortalTarget={bodyRef.current}
                        />
                    </div> :
                        <div className="formulary-editor-select ml-5 mr-5">
                            <label>Unit</label>
                            <input
                                value={medication?.Unit || ""}
                                onChange={textInputHandler}
                                type="text"
                                id="Unit"
                                className="quantity-input"
                                name="Unit"
                                placeholder="Units"
                                disabled={!medication.ProductName}
                            />
                        </div>
                    }

                    <div className="formulary-editor-select mr-2 ml-5">
                        <label>Dispense Quantity</label>
                        <input
                            value={medication?.Quantity || ""}
                            onChange={textInputHandler}
                            type="number"
                            id="DispenseQuantity"
                            className={error.includes("Quantity") ? "quantity-error" : "quantity-input"}
                            name="Quantity"
                            placeholder="Number" />
                    </div>
                </div>
                <div className="formulary-editor-block mt-4">
                    <div className="formulary-editor-select mr-5">
                        <label>Refill(s) Quantity</label>
                        <input
                            value={medication?.Refills || ""}
                            onChange={textInputHandler}
                            type="number"
                            id="RefillQuantity"
                            className={error.includes("Refills") ? "quantity-error" : "quantity-input"}
                            name="Refills"
                            placeholder="Number"
                        />
                    </div>
                    <div className="formulary-editor-select ml-5 mr-5">
                        <label>Days Supply</label>
                        <input
                            value={medication?.DaysSupply || ""}
                            onChange={textInputHandler}
                            type="number" id="DaysSupply"
                            className={error.includes("DaysSupply") ? "quantity-error" : "quantity-input"}
                            name="DaysSupply"
                            placeholder="Number"
                        />
                    </div>

                    <div className="formulary-editor-select mr-2 ml-5">
                        <label>Usage Instructions</label>
                        <input
                            value={medication?.UsageInstructions || ""}
                            onChange={textInputHandler}
                            type="text"
                            id="UsageInstructions"
                            className="quantity-input"
                            name="UsageInstructions"
                            placeholder="Type" />
                    </div>

                </div>
                <div className="formulary-editor-block mt-4">
                    <div className="formulary-editor-select mr-5">
                        <label>Provider Protocol Displayed in Visit</label>
                        <input
                            value={medication?.Protocol || ""}
                            onChange={textInputHandler}
                            type="text"
                            id="Protocol"
                            className="quantity-input p-2"
                            name="Protocol"
                            placeholder="Type"
                        />
                    </div>
                    <div className="formulary-editor-select mr-5 ml-5">
                        <label>Reference Number</label>
                        <input
                            value={medication?.ReferenceNumber || ""}
                            onChange={textInputHandler}
                            type="text"
                            id="ReferenceNumber"
                            className="quantity-input p-2"
                            name="ReferenceNumber"
                            placeholder="Type"
                        />
                    </div>
                    <div className="formulary-editor-select mr-2 ml-5">
                        <label>Active for Providers</label>
                        <Select
                            options={activeOptions}
                            onChange={(e) => selectInputHandler(e, "Active")}
                            styles={customStyles}
                            name="Active"
                            value={setSelectInputValue(medication.Active, activeOptions)}
                            isSearchable={false}
                            menuPortalTarget={bodyRef.current}
                        />
                    </div>

                </div>
                <div className="formulary-editor-block mt-4">
                    <div className="formulary-editor-select mr-5">
                        <label>Partner Price</label>
                        <input
                            value={medication?.PartnerPrice || ""}
                            onChange={textInputHandler}
                            type="text"
                            id="PartnerPrice"
                            className="quantity-input"
                            name="PartnerPrice"
                            placeholder="Type"
                        />
                    </div>
                    <div className="formulary-editor-select ml-5 mr-5">
                        <label>Partner Product Name</label>
                        <input
                            value={medication?.PartnerProductName || ""}
                            onChange={textInputHandler}
                            type="text"
                            id="PartnerProductName"
                            className="quantity-input"
                            name="PartnerProductName"
                            placeholder="Type"
                        />
                    </div>
                    <div className="formulary-editor-select mr-2 ml-5">
                        <label>Partner Service ID</label>
                        <input
                            value={medication?.PartnerServiceID || ""}
                            onChange={textInputHandler}
                            type="text"
                            id="PartnerServiceID"
                            className="quantity-input"
                            name="PartnerServiceID"
                            placeholder="Type"
                        />
                    </div>

                </div>
                <div className="formulary-editor-block mt-4">
                    <div className="formulary-editor-select mr-5">
                        <label>Partner Dosage</label>
                        <input
                            value={medication?.PartnerDosage || ""}
                            onChange={textInputHandler}
                            type="text"
                            id="PartnerDosage"
                            className="quantity-input"
                            name="PartnerDosage"
                            placeholder="Type"
                        />
                    </div>
                    <div className="formulary-editor-select ml-5 mr-5">
                        <label>Partner Medication Route + Type</label>
                        <input
                            value={medication?.PartnerMedicationRouteType || ""}
                            onChange={textInputHandler}
                            type="text"
                            id="PartnerMedicationRouteType"
                            className="quantity-input"
                            name="PartnerMedicationRouteType"
                            placeholder="Type"
                        />
                    </div>
                    <div className="formulary-editor-select mr-2 ml-5">
                        <label>Partner Default Status</label>
                        <input
                            value={medication?.PartnerDefaultStatus || ""}
                            onChange={textInputHandler}
                            type="text"
                            id="PartnerDefaultStatus"
                            className="quantity-input"
                            name="PartnerDefaultStatus"
                            placeholder="Type"
                        />
                    </div>

                </div>
                <div className="formulary-editor-block mt-4">
                    <div className="formulary-editor-select mr-5">
                        <label>Partner Hidden Status</label>
                        <input
                            value={medication?.PartnerHiddenStatus || ""}
                            onChange={textInputHandler}
                            type="text"
                            id="PartnerHiddenStatus"
                            className="quantity-input"
                            name="PartnerHiddenStatus"
                            placeholder="Type"
                        />
                    </div>
                    <div className="formulary-editor-select ml-5 mr-5">
                        <label>Partner Change Notes</label>
                        <input
                            value={medication?.PartnerChangeNotes || ""}
                            onChange={textInputHandler}
                            type="text"
                            id="PartnerChangeNotes"
                            className="quantity-input"
                            name="PartnerChangeNotes"
                            placeholder="Type"
                        />
                    </div>
                    <div className="formulary-editor-select mr-2 ml-5">
                        <label>Partner Field 9</label>
                        <input
                            value={medication?.PartnerField9 || ""}
                            onChange={textInputHandler}
                            type="text"
                            id="PartnerField9"
                            className="quantity-input"
                            name="PartnerField9"
                            placeholder="Type"
                        />
                    </div>

                </div>
                <div className="formulary-editor-block mt-4">
                    <div className="formulary-editor-select mr-5">
                        <label>Partner Field 10</label>
                        <input
                            value={medication?.PartnerField10 || ""}
                            onChange={textInputHandler}
                            type="text"
                            id="PartnerField10"
                            className="quantity-input"
                            name="PartnerField10"
                            placeholder="Type"
                        />
                    </div>
                    <div className="formulary-editor-select ml-5 mr-2">
                        { }
                    </div>
                    <div className="formulary-editor-select  mr-3">
                        { }
                    </div>
                </div>
            </div>
            <div className="d-flex edit-add-btns justify-content-end mt-4 mb-5">
                <button type="button" className="exit" onClick={handleCancel}>Exit without saving</button>
                <button type="button" className="save" onClick={handleSave}>Save</button>
            </div>
        </>
    )
}
export default AddOrEdit
