import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { SET_IDLE_LOGOUT_TIME, LOGOUT } from "../constants/actionTypes";
import api from "../api";
import bugsnagClient from "../services/bugsnag";

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: state.common.currentUser,
    currentTime: state.time.currentTime,
    idleLogoutTime: state.time.idleLogoutTime,
  };
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
const mapDispatchToProps = (dispatch) => ({
  setIdleTime: () => dispatch({ type: SET_IDLE_LOGOUT_TIME }),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'payload' implicitly has an 'any' type.
  logout: (payload) => dispatch({ type: LOGOUT, payload }),
});

class IdleMonitor extends Component {
  constructor(props) {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
    super(props);
    this.state = { showAlert: false };
    this.onConfirm = this.onConfirm.bind(this);
  }

  componentDidMount() {
    this.props.setIdleTime();
  }

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'setIdleTime' does not exist on type 'Rea... Remove this comment to see the full error message
  componentDidUpdate(prevProps) {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'prevProps' implicitly has an 'any' type... Remove this comment to see the full error message
    if (this.props.currentUser) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentUser' does not exist on type 'Rea... Remove this comment to see the full error message
      if (!this.state.showAlert && this.props.currentTime > this.props.idleLogoutTime) {
        this.setState({
          logoutAt: this.props.currentTime + 20000,
          showAlert: true,
        });
      }
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentTime' does not exist on type 'Rea... Remove this comment to see the full error message
      if (this.state.logoutAt && this.props.currentTime > this.state.logoutAt) {
        this.props.logout({});
      }
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'logoutAt' does not exist on type 'Readon... Remove this comment to see the full error message
      if (!prevProps.currentUser) {
        bugsnagClient.user = {
          name: this.props.currentUser.firstName + " " + this.props.currentUser.lastName,
          providerID: this.props.currentUser.providerID,
        };
      }
    }
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentUser' does not exist on type 'Rea... Remove this comment to see the full error message
    if (prevProps.currentUser && !this.props.currentUser) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentUser' does not exist on type 'Rea... Remove this comment to see the full error message
      this.setState({ showAlert: false, logoutAt: null });
      bugsnagClient.user = null;
    }
  }

  logout() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentUser' does not exist on type 'Rea... Remove this comment to see the full error message
    this.props.logout(api.Availability.clockOut());
  }

  onConfirm() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentUser' does not exist on type 'Rea... Remove this comment to see the full error message
    this.setState({ showAlert: false, logoutAt: null });
    this.props.setIdleTime();
  }

  render() {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'object'.
    if (!this.state.showAlert || !this.props.currentUser) {
      return null;
    }

    return (
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'logout' does not exist on type 'Readonly... Remove this comment to see the full error message
      <div className="modal-overlay d-flex flex-column">
        <div className="idle-logout d-flex flex-column justify-content-between align-items-center">
          {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'setIdleTime' does not exist on type 'Rea... Remove this comment to see the full error message */}
          <h4>Are you still there? </h4>
          {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'showAlert' does not exist on type 'Reado... Remove this comment to see the full error message */}
          <p>Click the button below to remain logged in </p>
          <Button onClick={this.onConfirm} color="primary">
            I'm still here!
          </Button>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IdleMonitor);
