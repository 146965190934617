import React, { FC, useEffect, useState } from "react";
import { Button, Row, Col } from "reactstrap";
import Octicon, { X } from "@githubprimer/octicons-react";
import ProviderSelect from "./ProviderSelect";
import styles from "./forwardModal.scss"

type Props = {
  visible: boolean;
  onCancel: () => void;
  onAssign: (referenceID:string) => void;
  encounterID: { ID: string; Kind: string };
  handleSelect: () => void;
  selected: { target: { value: string } };
  state: string;
};

const ForwardVisitModal:FC<Props> = ({
  visible,
  onCancel,
  onAssign,
  encounterID,
  handleSelect,
  selected,
  state,
}) => {
  const [referenceID, setReferenceID] = useState("");

  useEffect(() => {
    if (encounterID?.ID && encounterID?.Kind) {
      let formmedReference = `${encounterID.ID}|${encounterID.Kind}`;
      setReferenceID(formmedReference);
    }
  }, [encounterID]);

  const handleCancel = () => {
    setReferenceID("");
    onCancel();
  };

  if (!visible) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className={`ml-auto mr-auto ${styles.assignVisit}`}>
        <Row>
          <Col>
            <h4 className={styles.assignHeader}>Assign to Another Provider</h4>
          </Col>
          <Button onClick={handleCancel} className={styles.assignExitBtn}>
            <Octicon icon={X} />
          </Button>
        </Row>
        <Row size={8}>
          <Col>
            <ProviderSelect
              className={styles.assignSelect}
              selected={selected}
              onChange={handleSelect}
              encounterID={referenceID}
              state={state}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <p className={styles.assignSubText}>
              Once assigned, you will not be able to retrieve this note unless it is re-assigned it
              back to you.
            </p>
          </Col>
        </Row>
        <Row>
          <div className={styles.assignBtnGrp}>
            <Button onClick={handleCancel} className={styles.assignCancelBtn}>
              Cancel
            </Button>
            <Button onClick={() => onAssign(referenceID)} className={styles.assignBtn}>
              Assign
            </Button>
          </div>
        </Row>
      </div>
    </div>
  );
};
export default ForwardVisitModal;
