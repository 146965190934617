import React, { useEffect } from "react";
import sound from '../sounds/toast_sound.mp3';

const ToastWithSound = ({ text }) => {
  const toastShow = () => {
    const audio = new Audio(sound);
    audio.preload = "auto";
    audio.onloadedmetadata = () => {
      audio?.play();
    };
  }
  useEffect(() =>(
    toastShow()
  ),[]);
  return (
    <span>
      {text}
    </span>
  )
}
export default ToastWithSound;
