import React, { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Table , Button} from "reactstrap";
import api from "../../api";
import momentz from "moment-timezone";
import { IUpdateLogEvents } from "../../constants/Types";
import "./UpdateLogTable.scss";
import ButtonSpinner from "../ButtonSpinner";

type ProviderUpdateLogProps = {
  providerID: string;
};

const FIELD_LABELS = [
  { value: "Capabilities.Demographic", label: "Demographic Capabilities" },
  { value: "Capabilities.Geographic", label: "Licensed States" },
  { value: "Capabilities.Linguistic", label: "Language Capabilities" },
  { value: "Street", label: "Street" },
  { value: "City", label: "City" },
  { value: "State", label: "State" },
  { value: "PostalCode", label: "Zip" },
  { value: "Phone", label: "Phone" },
  { value: "Email", label: "Email" },
  { value: "Prefix", label: "Prefix" },
  { value: "FirstName", label: "First Name" },
  { value: "LastName", label: "Last Name" },
  { value: "Suffix", label: "Suffix" },
  { value: "DateOfBirth", label: "Date of Birth" },
  { value: "DEANumber", label: "DEA Number" },
  { value: "NPINumber", label: "NPI Number" },
  { value: "IsBetaTester", label: "Beta Tester" },
  { value: "DisableAlerts", label: "Disabled Text Alerts" },
  { value: "enableToastifyNotifications", label: "Enable Toastify Notifications" },
  { value: "enableBrowserNotifications", label: "Enable Browser Notifications" },
  { value: "receiveSupervisingPhysicianEmails", label: "Receive Supervising Physician Emails" },
  { value: "MedicalDirectors", label: "Medical Directors" },
  { value: "SupervisorUpdatedAt", label: "Supervisor Updated" },
  { value: "Permissions", label: "Permissions" },
  { value: "DoseSpotID", label: "DoseSpot ID" },
  { value: "MedicalPractices", label: "Medical Practices" },
  { value: "CreatedAt", label: "Created" },
  { value: "InvitePending", label: "Invite Pending" },
  { value: "Active", label: "Active" },
  { value: "CurrentPracticeID", label: "Current Practice ID" },
  { value: "VideoSetup", label: "Video Setup" },
  { value: "SignatureUploaded", label: "Signature Uploaded" },
  { value: "HomeBaseProviderID", label: "Home Base ID" },
  { value: "GroupSort", label: "Group Sort" },
];

const getDate = (dt) => momentz(dt).format("MM/DD/YYYY h:mm a");
const getZone = momentz.tz.guess();
const zone = momentz.tz(getZone).zoneAbbr();

const TableRows = ({ item, idx }) => {
  const Grouped =
    item?.changedProperties &&
    Object.entries(item?.changedProperties)?.map(([key, value]) => {
      return {
        field: key,
        old: item.changedProperties[key].__oldValue,
        new: item.changedProperties[key].__newValue,
        name: item.triggeredByName,
        createdAt: item.createdAt,
      };
    });

  // format items in medical direcors object
  const MedicalDirector = (Directors) => {
    //  return providerName(state)
    if (!!Directors) {
      return Object.entries(Directors)?.map(([key, value]) => {
        return `${value} (${key})`;
      });
    } else return;
  };

  // format Superviser Updated At times
  const SuperviserUpdatedAt = (Time) => {
    if (!!Time) {
      return Object.entries(Time)?.map(([key, value]) => {
        return `${getDate(value)}${zone} (${key})`;
      });
    }
  };

  return (
    <>
      {Grouped?.map((event) => {
        const { field, old: oldValue, new: newValue, name, createdAt } = event;

        const fieldName = FIELD_LABELS?.find((f) => f.value === field);
        // format old values
        const oldValueString =
          field === "MedicalDirectors"
            ? MedicalDirector(oldValue)?.join(", ")
            : field === "SupervisorUpdatedAt"
            ? SuperviserUpdatedAt(oldValue)?.join(", ")
            : typeof oldValue === "boolean"
            ? oldValue
              ? "Yes"
              : "No"
            : Array.isArray(oldValue)
            ? oldValue?.join(",  ")
            : oldValue;

        // format new values
        const newValueString =
          field === "MedicalDirectors"
            ? MedicalDirector(newValue)?.join(", ")
            : field === "SupervisorUpdatedAt"
            ? SuperviserUpdatedAt(newValue)?.join(", ")
            : typeof newValue === "boolean"
            ? newValue
              ? "Yes"
              : "No"
            : Array.isArray(newValue)
            ? newValue?.join(",  ")
            : newValue;

        const groupSortClass = fieldName?.value === "GroupSort" ? "group-sort" : "";

        return (
          <tr key={`${idx}-${fieldName?.label}`} className="updateLogTable-body">
            <td
              key={`${idx}-${fieldName?.label}-date`}
              className={`table-item ${groupSortClass}`}
            >{`${getDate(event?.createdAt)} ${zone}`}</td>
            <td key={`${idx}-${fieldName?.label}-label`} className={`table-item ${groupSortClass}`}>
              {fieldName?.label === "Active" ? "Provider Status" : fieldName?.label}
            </td>
            <td
              key={`${idx}-${fieldName?.label}-oldValue`}
              className={`table-item ${groupSortClass}`}
            >
              {oldValueString}
            </td>
            <td
              key={`${idx}-${fieldName?.label}-newValue`}
              className={`table-item ${groupSortClass}`}
            >
              {newValueString}
            </td>
            <td key={`${idx}-${fieldName?.label}-name`} className={`table-item ${groupSortClass}`}>
              {event?.name}
            </td>
          </tr>
        );
      })}
    </>
  );
};

const ProviderUpdateLog: FC<ProviderUpdateLogProps> = ({ providerID }) => {
  const [data, setData] = useState<any>([]);
  const [size, setSize] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true)
    fetchData();
  }, [size]);

  const fetchData = async () => {
    try {
      const response = await api.Providers.getUpdateLog(providerID, size);
      response?.events &&
        response?.events?.sort((a, b) => {
          if (a?.createdAt > b?.createdAt) {
            return -1;
          } else if (a?.createdAt < b?.createdAt) {
            return 1;
          }
        });
      let sorted = response?.events ? response?.events : [];
      setData(sorted);
      setLoading(false)
    } catch (error) {
      console.log("ERROR:: ", error);
    }
  };

  const handleSizeChange = () => {
    if(size === 10) {
      setSize(0);
    } else {
      setSize(10);
    }
  }

  if (!data || !data.length) {
    return null;
  }

  return (
    <div className="dashboard-component align-content-stretch">
      <h4>Provider Profile Edit History</h4>
      <Table className="update-log-table">
        <thead>
          <tr className="tableHead">
            <th>Date</th>
            {/* changedAt */}
            <th>Field Name</th>
            {/* changedProperties */}
            <th>Changed From</th>
            {/* oldValue */}
            <th>Changed To</th>
            {/* newValue */}
            <th>Changed By</th>
            {/* triggeredByName */}
          </tr>
        </thead>
        <tbody>
          {!!data &&
            data?.map((event, idx) => {
              return <TableRows key={`dataTableRows-${idx}`} item={event} idx={idx} />;
            })}
        </tbody>
      </Table>
      <div className="d-flex justify-content-center">
      {loading ? <Button className="m-auto" color="primary" style={{width: "121px"}}disabled><ButtonSpinner inProgress={loading}/></Button> : <Button className="m-auto" onClick={handleSizeChange} style={{width: "121px"}} color="primary">{size === 10 ? "Load More" : "Show Less"}</Button>}
      </div>
    </div>
  );
};

export default ProviderUpdateLog;
