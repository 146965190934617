import moment from "moment-timezone";

export const STATES = {
  AL: "Alabama",
  AK: "Alaska",
  AS: "American Samoa",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  FM: "Federated States Of Micronesia",
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MH: "Marshall Islands",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  MP: "Northern Mariana Islands",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PW: "Palau",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VI: "Virgin Islands",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

export const STATE_ABBREVIATION = {
  Alabama: "AL",
  Alaska: "AK",
  Arizona: "AZ",
  Arkansas: "AR",
  California: "CA",
  Colorado: "CO",
  Connecticut: "CT",
  Delaware: "DE",
  "District Of Columbia": "DC",
  Florida: "FL",
  Georgia: "GA",
  Guam: "GU",
  Hawaii: "HI",
  Idaho: "ID",
  Illinois: "IL",
  Indiana: "IN",
  Iowa: "IA",
  Kansas: "KS",
  Kentucky: "KY",
  Louisiana: "LA",
  Maine: "ME",
  Maryland: "MD",
  Massachusetts: "MA",
  Michigan: "MI",
  Minnesota: "MN",
  Mississippi: "MS",
  Missouri: "MO",
  Montana: "MT",
  Nebraska: "NE",
  Nevada: "NV",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  "New York": "NY",
  "North Carolina": "NC",
  "North Dakota": "ND",
  Ohio: "OH",
  Oklahoma: "OK",
  Oregon: "OR",
  Palau: "PW",
  Pennsylvania: "PA",
  "Rhode Island": "RI",
  "South Carolina": "SC",
  "South Dakota": "SD",
  Tennessee: "TN",
  Texas: "TX",
  Utah: "UT",
  Vermont: "VT",
  Virginia: "VA",
  Washington: "WA",
  "West Virginia": "WV",
  Wisconsin: "WI",
  Wyoming: "WY",
};

//states are assigned time zones based on corresponding cities in the region.
//eastern: New_York, central: Edmonton, mountain: Denver, Pacific: Los_Angeles

export const PROVIDER_TIME_ZONE = {
  AL: "America/Chicago",
  AK: "America/Nome",
  AS: "America/American_Samoa",
  AZ: "America/Phoenix",
  AR: "America/Chicago",
  CA: "America/Los_Angeles",
  CO: "America/Denver",
  CT: "America/New_York",
  DE: "America/New_York",
  DC: "America/New_York",
  FM: "Pacific/Chuuk",
  FL: "America/New_York",
  GA: "America/New_York",
  GU: "Pacific/Guam",
  HI: "Pacific/Honolulu",
  ID: "America/Denver",
  IL: "America/Chicago",
  IN: "America/Indianapolis",
  IA: "America/Chicago",
  KS: "America/Chicago",
  KY: "America/Louisville",
  LA: "America/Chicago",
  ME: "America/New_York",
  MH: "Pacific/Majuro",
  MD: "America/New_York",
  MA: "America/New_York",
  MI: "America/New_York",
  MN: "America/Chicago",
  MS: "America/Chicago",
  MO: "America/Chicago",
  MT: "America/Denver",
  NE: "America/Chicago",
  NV: "America/Los_Angeles",
  NH: "America/New_York",
  NJ: "America/New_York",
  NM: "America/Denver",
  NY: "America/New_York",
  NC: "America/New_York",
  ND: "America/Chicago",
  MP: "Pacific/Guam",
  OH: "America/New_York",
  OK: "America/Chicago",
  OR: "America/Los_Angeles",
  PW: "Pacific/Palau",
  PA: "America/New_York",
  PR: "America/Puerto_Rico",
  RI: "America/New_York",
  SC: "America/New_York",
  SD: "America/Chicago",
  TN: "America/Chicago",
  TX: "America/Chicago",
  UT: "America/Denver",
  VT: "America/New_York",
  VI: "America/St_Thomas",
  VA: "America/New_York",
  WA: "America/Los_Angeles",
  WV: "America/New_York",
  WI: "America/Chicago",
  WY: "America/Denver",
}

export const isPCCUser = (currentUser: any) => ((currentUser || {}).integrations || []).reduce((result: any, item: any) => {
  return !!result || ("" + item).toLowerCase() == "pcc";
}, false);

export const defaultColumns = [
  "providerName",
  "location",
  "phone",
  "status",
  "auditStatistics",
]

export const defaultSearchParameters = {
  scope: "all",
  provider: "",
  location: [],
  active: [],
  demographic: [],
  language: [],
  universal: "",
  practice: "",
  permissions: [],
  pageStart: 0,
  pageSize: 50,
  page: 0,
  withAuditStatistics: true,
}


export const activeChoices = [
  { value: "active", label: "Active" },
  { value: "disabled", label: "Disabled" },
];
export const demographicChoices = [
  { value: "child", label: "Children (0-13)"},
  { value: "adolescents", label: "Adolescents (14-17)"},
  { value: "adult", label: "Adults (18+)"}
]

export const languageChoices = [
  { value: "english", label: "English" },
  { value: "spanish", label: "Spanish" },
]

export const permissionChoices = [
  {
    value: "scribe",
    label: "Scribe",
    
  },
  {
    value: "seePatients",
    label: "See Patients",
    
  },
  {
    value: "supervisingPhysician",
    label: "Supervising Physician",
    
  },
  {
    value: "manage",
    label: "Manage Providers",
    
  },
  {
    value: "medicalDirector",
    label: "Medical Director",
    
  },
  {
    value: "monitor",
    label: "Monitor Activity",
    
  },
  {
    value: "prescribingAgent",
    label: "DoseSpot Prescribing Agent",
    
  },
  { value: "billing", label: "Billing" },
  {
    value: "telemedExtender",
    label: "Telemedicine Extender",
  
  },
  {
    value: "admin",
    label: "Admin",
    
  },
  {
    value: "recentEncounter",
    label: "Recent Encounter",
    
  },
  {
    value: "recentEncounterLimited",
    label:  "Recent Encounter Limited",
    
  },
  {
    value: "noPHI",
    label:  "No PHI",
    
  },
  {
    value: "providerPage",
    label:  "Provider Page",
  }
];

export const OtherOption = { value: "Other", label: "Other" };

export const suffixChoices = [
  { value: "MD", label: "MD" },
  { value: "DO", label: "DO" },
  { value: "NP", label: "NP" },
  { value: "RN", label: "RN" },
  { value: "Other", label: "Other" },
];

export const TIMEZONES = [
  { label: "Atlantic Time", value: "-04:00", timeZoneShift: -4 * 60 * 60 },
  { label: "Eastern Time", value: "-05:00", timeZoneShift: -5 * 60 * 60 },
  { label: "Central Time", value: "-06:00", timeZoneShift: -6 * 60 * 60 },
  { label: "Mountain Time", value: "-07:00", timeZoneShift: -7 * 60 * 60 },
  { label: "Pacific Time", value: "-08:00", timeZoneShift: -8 * 60 * 60 },
  { label: "Alaska Time", value: "-09:00", timeZoneShift: -9 * 60 * 60 },
  { label: "Hawaii-Aleutian Time", value: "-10:00", timeZoneShift: -10 * 60 * 60 },
];
export const TIMEZONES_BY_CITY = [
  {
    label: moment.tz("America/Chicago").zoneAbbr(),
    value:  moment.tz("America/Chicago").zoneAbbr(),
    zone: "America/Chicago",
  },
  {
    label: moment.tz("America/Denver").zoneAbbr(),
    value:  moment.tz("America/Denver").zoneAbbr(),
    zone: "America/Denver"
  },
  {
    label: moment.tz("America/Phoenix").zoneAbbr(),
    value:  moment.tz("America/Phoenix").zoneAbbr(),
    zone: "America/Phoenix",
  },
  {
    label: moment.tz("America/Los_Angeles").zoneAbbr(),
    value:  moment.tz("America/Los_Angeles").zoneAbbr(),
    zone: "America/Los_Angeles",
  },
  {
    label: moment.tz("America/Anchorage").zoneAbbr(),
    value:  moment.tz("America/Anchorage").zoneAbbr(),
    zone: "America/Anchorage",
  },
  {
    label: moment.tz("Pacific/Honolulu").zoneAbbr(),
    value:  moment.tz("Pacific/Honolulu").zoneAbbr(),
    zone: "Pacific/Honolulu",
  }
]
