import { defaultParams } from "./constants/Encounter";
import { toast } from "react-toastify";
import bugsnag from "./services/bugsnag";
import ToastWithSound from "./components/ToastSound";
import React from "react";
const EMRLogo = require("./images/emr-logo.png");

// I copied this from stackoverflow. No shame.
export function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return phoneNumberString;
}

export function showPushNotification(data) {
  if (!("Notification" in window)) {
    alert("This browser does not support desktop notification");
  } else if (Notification.permission === "granted") {
    // If it's okay let's create a notification
    new Notification(data.title, data);
  } else if (Notification.permission !== 'denied') {
    Notification.requestPermission(function (permission) {
      // Whatever the user answers, we make sure we store the information
      if(!('permission' in Notification)) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'permission' does not exist on type 'neve... Remove this comment to see the full error message
        Notification.permission = permission;
      }
      // If the user is okay, let's create a notification
      if (permission === "granted") {
        new Notification(data.title, data);
      }
    });
  }
}
export const openInNewTab = (url: string) => {
  let win = window.open(url, "_blank");
  if (win) {
    win.focus();
  }
};
export const checkIsJSON = (errorBody) => {
  try {
    JSON.parse(errorBody)
    return true
  }
  catch (e) {
    return false
  }
}
export const errorLogger = (error) => {
  const isJSON = checkIsJSON(error?.response?.body?.message);
  let errorMessage = isJSON
    ? JSON.parse(error?.response.body?.message)?.responseBody
    : error?.response?.body?.message || error?.response?.text || error?.message;
  toast.error(`Error: ${errorMessage || "Internal Error"}`);
  bugsnag.notify(error);
}


export const secondsToMinutes = (sec) => {
  
  const minutes = Math.floor(sec / 60);
  const seconds = sec % 60;

  let time = `${formatTime(minutes)}:${formatTime(seconds)}`

  return time

}

const formatTime = (t) => {
  return t.toString().padStart(2, '0')
}

export const minutesToSeconds = (min) => {
  let seconds = min * 60
  return seconds
}
export const isBetaSite = window.location.href.includes("emrbeta")

export const minutes = (sec) => {
  
  const minutes = Math.floor(sec / 60);


  let min = minutes.toString()

  return min

}
export const seconds = (sec) => {
  
  
  const seconds = sec % 60;

  let s = seconds.toString()

  return s

}
export const showInWRNotification = (currentUser) => {
  if (currentUser?.enableBrowserNotifications && currentUser?.enableToastifyNotifications) {
    showPushNotification({
      title: "New Patient Available",
      body: `New patient is in waiting room`,
      icon: EMRLogo,
    });
    toast.info(() => <ToastWithSound text={"New patient is in waiting room"} /> );

  } else if (currentUser?.enableBrowserNotifications) {
    showPushNotification({
      title: "New Patient Available",
      body: `New patient is in waiting room`,
      icon: EMRLogo,
    });
  } else if (currentUser?.enableToastifyNotifications) {
    toast.info( () => <ToastWithSound text={"New patient is in waiting room"} />)
  }
}

export const showInIntakeNotification = (currentUser) => {
  if (currentUser?.enableBrowserNotifications && currentUser?.enableToastifyNotifications) {
    showPushNotification({
      title: "New Patient Coming",
      body: `New patient is in intake`,
      icon: EMRLogo,
    });
    toast.info(`New patient is in intake`)
  } else if (currentUser?.enableBrowserNotifications) {
    showPushNotification({
      title: "New Patient Coming",
      body: `New patient is in intake`,
      icon: EMRLogo,
    });
  } else if (currentUser?.enableToastifyNotifications) {
    toast.info(`New patient is in intake`)
  }
}

export const browserNameParser = (agent) => {
    if (agent.includes("edge")) return "MS Edge";
    if (agent.includes("edg/")) return "Edge ( chromium based)";
    if (agent.includes("opr"))  return "Opera";
    if (agent.includes("chrome")) return "Chrome";
    if (agent.includes("trident"))return "MS IE";
    if (agent.includes("firefox")) return "Mozilla Firefox";
    if (agent.includes("safari")) return "Safari"
  return "other";
}
export const OSParser = (agent) => {
  if (agent.includes("win")) return "Windows";
  if (agent.includes("mac")) return "MacOS";
  if (agent.includes("x11")) return "UNIX";
  if (agent.includes("linux"))return "Linux";
  return "other"
}

export const isMobileOS = (agent) => {
  if (/android/i.test(agent)) {
    return "Android";
  }
  if (/Windows Phone/i.test(agent)) {
    return "Windows Phone";
  }
  if (/iPad|iPhone|iPod/.test(agent)) {
    return "iOS";
  }
  return false;
}
