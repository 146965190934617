import React, { FC } from 'react';
import Chart from "react-apexcharts";
const WEEK_VIEW = "Week View";
const DAY_VIEW = "Day View";

var options = {
    series: [{
        name: '12:00am',
        data: {
            min: 0,
            max: 90
        }
    },
    {
        name: '12:30am',
        data: {
            min: 0,
            max: 90
        }
    },
    {
        name: '1:00am',
        data: {
            min: 0,
            max: 90
        }
    },
    {
        name: '1:30am',
        data: {
            min: 0,
            max: 90
        }
    },
    {
        name: '2:00am',
        data: {
            min: 0,
            max: 90
        }
    },
    {
        name: '2:30am',
        data: {
            min: 0,
            max: 90
        }
    },
    {
        name: '3:00am',
        data: {
            min: 0,
            max: 90
        }
    },
    {
        name: '3:30am',
        data: {
            min: 0,
            max: 90
        }
    },
    {
        name: '4:00am',
        data: {
            min: 0,
            max: 90
        }
    }
    ],
    chart: {
        height: 350,
        type: 'heatmap',
    },
    dataLabels: {
        enabled: false
    },
    colors: ["#008FFB"],
    title: {
        // text: 'HeatMap Chart (Single color)'
    },
    heatmap: { radius: 10 }
};

type Props = {
    view?: string,
    zone: { label: string, value: string, zone: string }
}

const HeatMap: FC<Props> = ({ view, zone }) => {

    const series = [
        {
            name: "12:00am",
            data: [
                { x: "AL", y: "12" },
                { x: "AK", y: "17" },
                { x: "CA", y: "18" },
                { x: "DE", y: "19" },
                { x: "NV", y: "10" },
                { x: "WI", y: "11" },
                { x: "TX", y: "12" },
                { x: "LA", y: "13" },
                { x: "KI", y: "14" },
                { x: "NY", y: "16" },
                { x: "ND", y: "17" },
            ]
        },
        {
            name: "12:30am",
            data: [
                { x: "AL", y: "12" },
                { x: "AK", y: "17" },
                { x: "CA", y: "18" },
                { x: "DE", y: "19" },
                { x: "NV", y: "10" },
                { x: "WI", y: "11" },
                { x: "TX", y: "12" },
                { x: "LA", y: "13" },
                { x: "KI", y: "14" },
                { x: "NY", y: "1" },
                { x: "ND", y: "17" },
            ]
        },
        {
            name: "1:00am",
            data: [
                { x: "AL", y: "12" },
                { x: "AK", y: "17" },
                { x: "CA", y: "18" },
                { x: "DE", y: "19" },
                { x: "NV", y: "10" },
                { x: "WI", y: "11" },
                { x: "TX", y: "12" },
                { x: "LA", y: "13" },
                { x: "KI", y: "14" },
                { x: "NY", y: "1" },
                { x: "ND", y: "17" },
            ]
        },
        {
            name: "1:30am",
            data: [
                { x: "AL", y: "12" },
                { x: "AK", y: "17" },
                { x: "CA", y: "18" },
                { x: "DE", y: "19" },
                { x: "NV", y: "10" },
                { x: "WI", y: "11" },
                { x: "TX", y: "12" },
                { x: "LA", y: "13" },
                { x: "KI", y: "14" },
                { x: "NY", y: "1" },
                { x: "ND", y: "17" },
            ]
        },
        {
            name: "2:00am",
            data: [
                { x: "AL", y: "12" },
                { x: "AK", y: "17" },
                { x: "CA", y: "18" },
                { x: "DE", y: "19" },
                { x: "NV", y: "10" },
                { x: "WI", y: "11" },
                { x: "TX", y: "12" },
                { x: "LA", y: "13" },
                { x: "KI", y: "14" },
                { x: "NY", y: "1" },
                { x: "ND", y: "17" },
            ]
        },
        {
            name: "2:30am",
            data: [
                { x: "AL", y: "12" },
                { x: "AK", y: "17" },
                { x: "CA", y: "18" },
                { x: "DE", y: "19" },
                { x: "NV", y: "10" },
                { x: "WI", y: "11" },
                { x: "TX", y: "12" },
                { x: "LA", y: "13" },
                { x: "KI", y: "14" },
                { x: "NY", y: "1" },
                { x: "ND", y: "17" },
            ]
        },
        {
            name: "3:00am",
            data: [
                { x: "AL", y: "12" },
                { x: "AK", y: "17" },
                { x: "CA", y: "18" },
                { x: "DE", y: "19" },
                { x: "NV", y: "10" },
                { x: "WI", y: "11" },
                { x: "TX", y: "12" },
                { x: "LA", y: "13" },
                { x: "KI", y: "14" },
                { x: "NY", y: "1" },
                { x: "ND", y: "17" },
            ]
        },
        {
            name: "3:30am",
            data: [
                { x: "AL", y: "12" },
                { x: "AK", y: "17" },
                { x: "CA", y: "18" },
                { x: "DE", y: "19" },
                { x: "NV", y: "10" },
                { x: "WI", y: "11" },
                { x: "TX", y: "12" },
                { x: "LA", y: "13" },
                { x: "KI", y: "14" },
                { x: "NY", y: "11" },
                { x: "ND", y: "17" },
            ]
        },
        {
            name: "4:00am",
            data: [
                { x: "AL", y: "12" },
                { x: "AK", y: "17" },
                { x: "CA", y: "18" },
                { x: "DE", y: "19" },
                { x: "NV", y: "10" },
                { x: "WI", y: "11" },
                { x: "TX", y: "12" },
                { x: "LA", y: "13" },
                { x: "KI", y: "14" },
                { x: "NY", y: "1" },
                { x: "ND", y: "17" },
            ]
        },
        {
            name: "4:30am",
            data: [
                { x: "AL", y: "12" },
                { x: "AK", y: "17" },
                { x: "CA", y: "18" },
                { x: "DE", y: "19" },
                { x: "NV", y: "10" },
                { x: "WI", y: "11" },
                { x: "TX", y: "12" },
                { x: "LA", y: "13" },
                { x: "KI", y: "14" },
                { x: "NY", y: "1" },
                { x: "ND", y: "17" },
            ]
        },
        {
            name: "5:00am",
            data: [
                { x: "AL", y: "12" },
                { x: "AK", y: "17" },
                { x: "CA", y: "18" },
                { x: "DE", y: "19" },
                { x: "NV", y: "10" },
                { x: "WI", y: "11" },
                { x: "TX", y: "12" },
                { x: "LA", y: "13" },
                { x: "KI", y: "14" },
                { x: "NY", y: "1" },
                { x: "ND", y: "17" },
            ]
        },
        {
            name: "5:30am",
            data: [
                { x: "AL", y: "12" },
                { x: "AK", y: "17" },
                { x: "CA", y: "18" },
                { x: "DE", y: "19" },
                { x: "NV", y: "10" },
                { x: "WI", y: "11" },
                { x: "TX", y: "12" },
                { x: "LA", y: "13" },
                { x: "KI", y: "14" },
                { x: "NY", y: "22" },
                { x: "ND", y: "17" },
            ]
        }
    ]
    const series2 = [
        {
            name: "12:00am",
            data: [
                { x: "11/1", y: "12" },
                { x: "11/2", y: "17" },
                { x: "11/3", y: "18" },
                { x: "11/4", y: "19" },
                { x: "11/5", y: "10" },
                { x: "11/6", y: "11" },
                { x: "11/7", y: "12" },
            ]
        },
        {
            name: "12:30am",
            data: [
                { x: "11/1", y: "12" },
                { x: "11/2", y: "17" },
                { x: "11/3", y: "18" },
                { x: "11/4", y: "19" },
                { x: "11/5", y: "10" },
                { x: "11/6", y: "11" },
                { x: "11/7", y: "12" },
            ]
        },
        {
            name: "1:00am",
            data: [
                { x: "11/1", y: "12" },
                { x: "11/2", y: "17" },
                { x: "11/3", y: "18" },
                { x: "11/4", y: "19" },
                { x: "11/5", y: "10" },
                { x: "11/6", y: "11" },
                { x: "11/7", y: "12" },
            ]
        },
        {
            name: "1:30am",
            data: [
                { x: "11/1", y: "12" },
                { x: "11/2", y: "17" },
                { x: "11/3", y: "18" },
                { x: "11/4", y: "19" },
                { x: "11/5", y: "10" },
                { x: "11/6", y: "11" },
                { x: "11/7", y: "12" },
            ]
        },
        {
            name: "2:00am",
            data: [
                { x: "11/1", y: "12" },
                { x: "11/2", y: "17" },
                { x: "11/3", y: "18" },
                { x: "11/4", y: "19" },
                { x: "11/5", y: "10" },
                { x: "11/6", y: "11" },
                { x: "11/7", y: "12" },
            ]
        },
        {
            name: "2:30am",
            data: [
                { x: "11/1", y: "12" },
                { x: "11/2", y: "17" },
                { x: "11/3", y: "18" },
                { x: "11/4", y: "19" },
                { x: "11/5", y: "10" },
                { x: "11/6", y: "11" },
                { x: "11/7", y: "12" },
            ]
        },
        {
            name: "3:00am",
            data: [
                { x: "11/1", y: "12" },
                { x: "11/2", y: "17" },
                { x: "11/3", y: "18" },
                { x: "11/4", y: "19" },
                { x: "11/5", y: "10" },
                { x: "11/6", y: "11" },
                { x: "11/7", y: "12" },
            ]
        },
        {
            name: "3:30am",
            data: [
                { x: "11/1", y: "12" },
                { x: "11/2", y: "17" },
                { x: "11/3", y: "18" },
                { x: "11/4", y: "19" },
                { x: "11/5", y: "10" },
                { x: "11/6", y: "11" },
                { x: "11/7", y: "12" },
            ]
        },
        {
            name: "4:00am",
            data: [
                { x: "11/1", y: "12" },
                { x: "11/2", y: "17" },
                { x: "11/3", y: "18" },
                { x: "11/4", y: "19" },
                { x: "11/5", y: "10" },
                { x: "11/6", y: "11" },
                { x: "11/7", y: "12" },
            ]
        },
        {
            name: "4:30am",
            data: [
                { x: "11/1", y: "12" },
                { x: "11/2", y: "17" },
                { x: "11/3", y: "18" },
                { x: "11/4", y: "19" },
                { x: "11/5", y: "10" },
                { x: "11/6", y: "11" },
                { x: "11/7", y: "12" },
            ]
        },
        {
            name: "5:00am",
            data: [
                { x: "11/1", y: "12" },
                { x: "11/2", y: "17" },
                { x: "11/3", y: "18" },
                { x: "11/4", y: "19" },
                { x: "11/5", y: "10" },
                { x: "11/6", y: "11" },
                { x: "11/7", y: "12" },
            ]
        },
        {
            name: "5:30am",
            data: [
                { x: "11/1", y: "12" },
                { x: "11/2", y: "17" },
                { x: "11/3", y: "18" },
                { x: "11/4", y: "19" },
                { x: "11/5", y: "10" },
                { x: "11/6", y: "11" },
                { x: "11/7", y: "12" },
            ]
        }
    ]
    // styling
    const options = {
        chart: {
            type: 'heatmap',
            foreColor: '#444444',
            dataLabels: {
                enabled: true,
                backgound: {
                    foreColor: '#444444',
                    padding: 5,
                }
            },
        },
        colors: ["#C5DEFF"],
        xaxis: {
            position: "top"
        },
        dataLabels: {
            enabled: true,
            style: {
                fontSize: '14px',
                fontWeight: 'bold',
                // colors: ['#000000'],
            },
        },
        stroke: {
            width: 5
        },

        tooltip: {
            enabled: false,
        },
        plotOptions: {
            heatmap: {
                radius: 12,
                padding: 4,
                enableShades: true,
                shadeIntensity: 0.5,
            }
        },

        axisBorder: {
            show: false,
            color: '#000000',
            height: 30,
            width: '100%',
            offsetX: 20,
            offsetY: 20
        },
        axisTicks: {
            show: false,
            borderType: 'solid',
            color: '#1175a7',
            height: 6,
            offsetX: 0,
            offsetY: 0
        },
    }

    const seriesData = view === WEEK_VIEW ? series2 : series

    return (
        <div >
            <Chart
                type="heatmap"
                series={seriesData}
                // width={"100%"}
                // height="auto"
                options={options}
            />
        </div>
    )
}

export default HeatMap
